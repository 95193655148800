// NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import "../assets/styles/notFound.css"


const NotFoundPage = () => {
  return (
    <>
    <section class="error-container">
      <span class="four"><span class="screen-reader-text">4</span></span>
      <span class="zero"><span class="screen-reader-text">0</span></span>
      <span class="four"><span class="screen-reader-text">4</span></span>
    </section>
    <div class="link-container">
      <Link to="/" className="more-link">Back Home</Link>
    </div>
    </>
  );
}

export default NotFoundPage;
