// NotificationSidebar.js

import React from "react";
import { Drawer, List } from "antd";

const NotificationSidebar = ({ visible, onClose }) => {
  const notifications = []; // Replace with your actual notifications array

  return (
    <Drawer
      title="Notifications"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      width={300}
    >
      <List
        dataSource={notifications}
        renderItem={(item) => (
          <List.Item>
            {/* Render your notification item */}
            {item.content}
          </List.Item>
        )}
      />
    </Drawer>
  );
};

export default NotificationSidebar;
