import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Badge,
  Modal,
} from "react-bootstrap";
import { backendURL } from "../../backendURL";
import { useLocation, useNavigate } from "react-router-dom";
import Camera from "../Camera";
import { message } from "antd";
import PageVisibilityWarning from "./PageVisibilityWarning ";

const QuestionForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { examId, duration, examName } = state || {};
  console.log(examId,duration)
  const initialSeconds = duration * 60;

  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(true);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [cameraAccess, setCameraAccess] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSave, setIsSave] = useState(true);

  // Timer logic
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
        handleAutoSubmit();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  // Fetch questions on component mount
  useEffect(() => {
    // console.log("fetching questions on component")
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID not found in local storage");
      }
      const response = await fetch(`${backendURL}/question/exam/${examId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch questions");
      }
      const data = await response.json();
      console.log("all questions",data)
      setAllQuestions(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching questions:", error);
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < allQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOption("");
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      setSelectedOption("");
    }
  };

  const handleSave = async () => {
    try {
      const currentTime = formatTime(seconds);
      const saved = {
        studentId: localStorage.getItem("userId"),
        examId,
        queId: allQuestions[currentQuestionIndex]._id,
        answer: selectedOption,
        time: currentTime,
      };

      console.log("Saving:", saved);

      const response = await fetch(`${backendURL}/answers/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(saved),
      });

      if (!response.ok) {
        throw new Error("Failed to save answer");
      }

      const data = await response.json();
      console.log("Response:", data);

      // Update answeredQuestions and move to next question
      setAnsweredQuestions((prev) => [...prev, allQuestions[currentQuestionIndex]._id]);

    } catch (error) {
      console.error("Failed to save:", error.message);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderQuestion = () => {
    const currentQuestion = allQuestions[currentQuestionIndex];
    if (!currentQuestion) return null;
    return (
      <>
        <Card.Title>
          Q.{currentQuestionIndex + 1}: {currentQuestion.questionText}
        </Card.Title>
        <Form>
          <div className="mb-3">
            {currentQuestion.options.map((option, index) => (
              <Form.Check
                key={index}
                type="radio"
                label={option}
                name="options"
                value={option}
                onChange={handleOptionChange}
                checked={selectedOption === option}
              />
            ))}
          </div>
        </Form>
      </>
    );
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCameraAccessChange = (access) => {
    setCameraAccess(access);
  };

  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const handleSubmit = async () => {
    createResult();
    message.success("Test submitted");
    navigate(`/submit/${examId}`);
    handleCloseConfirmationModal();
  };

  const handleAutoSubmit = async () => {
    createResult();
    message.success("Test Auto submit");
    navigate(`/submit/${examId}`);
  };

  const createResult=async()=>{
    try {
      const response = await fetch(`${backendURL}/result/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          examId,
          studentId: localStorage.getItem("userId"),
          classId: localStorage.getItem("userClass")
        }),
      });
      let data = await response.json();
      console.log(data)
      message.success("Result has been created successfully!");
    }
    catch(err){
      console.log(err)
      message.error("Failed to create result");
    }  
  }

  return (
    <>
    <PageVisibilityWarning onAutoSubmit={handleAutoSubmit} />
    <Container fluid className="p-4" style={{ marginTop: "7%" }}>
      <Camera onCameraAccessChange={handleCameraAccessChange} />
      <Row>
        <Col md={9}>
          <Card>
            <Card.Header as="h5">{examName}</Card.Header>
            <Card.Body>
              {renderQuestion()}
              <div className="d-flex justify-content-between mt-3">
                <Button
                  variant="primary"
                  className="me-2"
                  onClick={handlePrevious}
                  disabled={currentQuestionIndex === 0}
                >
                  Previous
                </Button>
                {currentQuestionIndex === allQuestions.length - 1 ? (
                  <Button variant="primary" onClick={handleSave}>
                    Save
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={handleSave}
                    >
                      Save and Next
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleNext}
                      disabled={
                        currentQuestionIndex === allQuestions.length - 1
                      }
                    >
                      Next
                    </Button>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Header as="h5">Time Left</Card.Header>
            <Card.Body>
              <h3>{formatTime(seconds)}</h3>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header as="h5" className="text-center">
              All Questions
            </Card.Header>
            <Card.Body>
              <div className="d-flex justify-content-center">
                <Row className="w-100">
                  {allQuestions.map((question, index) => (
                    <Col
                      key={index}
                      xs={3}
                      md={2}
                      className="d-flex justify-content-center align-items-center mb-3"
                    >
                      <Badge
                        pill
                        onClick={() => setCurrentQuestionIndex(index)}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor:
                            index === currentQuestionIndex
                              ? "blue"
                              : answeredQuestions.includes(question._id)
                              ? "green"
                              : "gray",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        {index + 1}
                      </Badge>
                    </Col>
                  ))}
                </Row>
              </div>
            </Card.Body>
          </Card>
          <Button
            variant="success"
            className="mt-3"
            onClick={handleSubmit}
          >
            Submit Test
          </Button>
        </Col>
      </Row>
      <Modal
        show={showConfirmationModal}
        onHide={handleCloseConfirmationModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Submit Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to submit the test?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseConfirmationModal}
          >
            Cancel
          </Button>
          <Button variant="success" onClick={()=>console.log("clicked")}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    </>
  );
};

export default QuestionForm;


