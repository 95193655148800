import React from 'react';

const CourseCategory = () => {
  return (
    <div>
      <section className="section category" aria-label="category">
        <div className="container">
          <p className="section-subtitle">Explore Our Services</p>
          <h2 className="h2 section-title">Our Offerings</h2>
          <ul className="grid-list">
            <li>
              <div className="category-card">
                <div className="card-icon">
                  <ion-icon name="school-outline" />
                </div>
                <div>
                  <h3 className="h3 card-title">
                    <a href="#">Mock Exams</a>
                  </h3>
                  <span className="card-meta">Practice and prepare with our mock exam papers</span>
                </div>
              </div>
            </li>
            <li>
              <div className="category-card">
                <div className="card-icon">
                  <ion-icon name="library-outline" />
                </div>
                <div>
                  <h3 className="h3 card-title">
                    <a href="#">Final Exams</a>
                  </h3>
                  <span className="card-meta">Prepare effectively for your final exams</span>
                </div>
              </div>
            </li>
            <li>
              <div className="category-card">
                <div className="card-icon">
                  <ion-icon name="document-text-outline" />
                </div>
                <div>
                  <h3 className="h3 card-title">
                    <a href="#">Study Materials</a>
                  </h3>
                  <span className="card-meta">Comprehensive study materials in PowerPoint and PDF formats</span>
                </div>
              </div>
            </li>
            <li>
              <div className="category-card">
                <div className="card-icon">
                  <ion-icon name="people-outline" />
                </div>
                <div>
                  <h3 className="h3 card-title">
                    <a href="#">Online Tutors</a>
                  </h3>
                  <span className="card-meta">Expert tutors available online for personalized guidance</span>
                </div>
              </div>
            </li>
            <li>
              <div className="category-card">
                <div className="card-icon">
                  <ion-icon name="school-outline" />
                </div>
                <div>
                  <h3 className="h3 card-title">
                    <a href="#">Scholarships</a>
                  </h3>
                  <span className="card-meta">Information on available scholarships and grants</span>
                </div>
              </div>
            </li>

            <li>
              <div className="category-card">
                <div className="card-icon">
                  <ion-icon name="megaphone-outline" />
                </div>
                <div>
                  <h3 className="h3 card-title">
                    <a href="#">Conferences</a>
                  </h3>
                  <span className="card-meta">Join upcoming conferences and seminars</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default CourseCategory;
