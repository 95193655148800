import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import certificateImg from '../assets/images/ScholarNet.png';
import { backendURL } from '../backendURL';

const { Title, Paragraph } = Typography;

const Certificate = () => {
  // State variables to hold dynamic data
  const [studentName, setStudentName] = useState('');
  const [examName, setExamName] = useState('');
  const [examPercentage, setExamPercentage] = useState('');
  const [certificateDate, setCertificateDate] = useState('');

  useEffect(() => {
    const fetchCertificateData = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Assuming userId is stored in localStorage
        const studentNameFromStorage = localStorage.getItem('userName'); // Assuming studentName is stored in localStorage

        if (!userId) {
          throw new Error('User ID not found in localStorage');
        }

        const response = await fetch(`${backendURL}/result/getResultByStudentid/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();

        // Assuming your API response structure is as follows
        const result = data.data[0]; // Assuming data is an array and we want the first item

        // Extracting relevant data
        const { examId, createdAt, percentage } = result;
        const { examName } = examId;

        setStudentName(studentNameFromStorage); // Set studentName from localStorage
        setExamName(examName);
        setExamPercentage(percentage);
        setCertificateDate(new Date(createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }));
      } catch (error) {
        console.error('Error fetching certificate data:', error);
        // Handle error state or show a message to the user
      }
    };

    fetchCertificateData();
  }, []);

  

  return (
    <>
    <div id="art">
      <svg
        id="art"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={700}
        height={700}
        viewBox="0 0 1500 1500"
      >
      
        <g id="Artboard_2" className="cls-3">
          <rect className="cls-29" width={1500} height={1500} />
          <g id="left-image" transform="translate(-222.941 79.324)">
            <path
              id="left-shape-1"
              className="cls-4"
              d="M222.941-79.324h282l-282,986Z"
            />
            <path
              id="left-shape-2"
              className="cls-5"
              d="M504.941,466.676H348.783l-125.842,440v56Z"
            />
            <path
              id="left-shape-3"
              className="cls-6"
              d="M425.7,197.749,348.783,466.676H504.941Z"
            />
          </g>
          <g id="right-image" transform="translate(1.677 -2.588)">
            <path
              id="right-shape-1"
              className="cls-4"
              d="M1498.823,101.588l-371.5,1401h371.5Z"
            />
            <path
              id="right-shape-2"
              className="cls-6"
              d="M1241.323,539.588H1382.68l-71.926,271.247Z"
            />
            <path
              id="right-shape-3"
              className="cls-5"
              d="M1498.823,54.588l-257.5,485H1382.68l116.143-438Z"
            />
            <path
              id="right-shape-4"
              className="cls-7"
              d="M1383.823,2.588l-142.5,537,257.5-485v-52Z"
            />
          </g>
          <g id="company-logo" transform="translate(800 1200)">
            <g id="Group_291" className="cls-24" transform="translate(0 0)">
              <rect
                id="Rectangle_71"
                className="cls-25"
                width={280}
                height={150}
              />
            </g>
          </g>
          <g id="company-logo-2" transform="translate(620 60)">
            <g id="Group_291-2" className="cls-8" transform="translate(0 0)">
              <rect
                id="Rectangle_71-2"
                className="cls-9"
                width={300}
                height={300}
                fill="url(#logo-pattern)" // Use logo pattern here
              />
            </g>
          </g>
          <text
            id="CERTIFICATE"
            className="cls-10"
            transform="translate(750 420.162)"
          >
            <tspan
              x={0}
              y={0}
              dominantBaseline="middle"
              textAnchor="middle"
            >
              CERTIFICATE
            </tspan>
          </text>
          <text
            id="OF_COMPLETION"
            className="cls-13"
            transform="translate(750 515.662)"
          >
            <tspan
              x={0}
              y={0}
              dominantBaseline="middle"
              textAnchor="middle"
            >
              OF COMPLETION
            </tspan>
          </text>
          <text
            id="STUDENT_NAME"
            className="cls-14"
            transform="translate(750 671.662)"
          >
            <tspan
              x={0}
              y={0}
              dominantBaseline="middle"
              textAnchor="middle"
            >
             {studentName}
            </tspan>
          </text>
          <text
            id="HAS_SUCCESSFULLY_COMPLEATED"
            className="cls-15"
            transform="translate(750 746)"
          >
            <tspan
              x={0}
              y={0}
              dominantBaseline="middle"
              textAnchor="middle"
            >
              has successfully completed
            </tspan>
          </text>
          <text
            id="COURSE_NAME"
            className="cls-16"
            transform="translate(750 825.377)"
          >
            <tspan
              x={0}
              y={0}
              dominantBaseline="middle"
              textAnchor="middle"
            >
              {examName} - {examPercentage}%
            </tspan>
          </text>
       
          <g id="Group_302" transform="translate(580 51)">
            <rect
              id="Rectangle_74"
              className="cls-18"
              width={336}
              height={96}
              rx={48}
              transform="translate(0 1028)"
            />
          </g>
          <g id="Group_300" transform="translate(0 21)">
          
            <g id="Group_303" transform="translate(308.333 41.5)">
            <text
                id="DATE"
                className="cls-23"
                transform="translate(1018.667 1300)"
              >
                <tspan
                  x={0}
                  y={0}
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  {certificateDate}
                </tspan>
              </text>
              <text
                id="DATE"
                className="cls-22"
                transform="translate(1018.667 1352.696)"
              >
                <tspan
                  x={0}
                  y={0}
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                 Exam DATE
                </tspan>
              </text>
            
            </g>
            <g id="Group_299" transform="translate(-827 41.5)">
              <text
                id="CERTIFICATE_NUMBER_TEXT"
                className="cls-20"
                transform="translate(1044 1352.696)"
              >
                <tspan
                  x={0}
                  y={0}
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  Certificate Number
                </tspan>
              </text>
              <text
                id="CERTIFICATE_NUMBER"
                className="cls-21"
                transform="translate(1044 1300)"
              >
                <tspan
                  x={0}
                  y={0}
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  A0B2C3D4
                </tspan>
              </text>
            </g>
            <g id="qrcode" transform="translate(540 1250)">
              <g
                id="Group_295"
                className="cls-27"
                transform="translate(0 0)"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <rect
                  id="Rectangle_75"
                  className="cls-28"
                  width={150}
                  height={150}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <canvas id="canvas" width={300} height={300}>
      300 by 300 Canvas divided into 4 colored squares of 150 by 150 each with 2px
      borders
    </canvas>
    <button type="button" id="printbutton">
      Print
    </button>
  </>
  );
};

export default Certificate;
