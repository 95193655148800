import React from 'react';
import PropTypes from 'prop-types';
import { Card, List, Typography, Space, Row, Col } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import img from "../assets/images/scholarship2.png"

const { Text } = Typography;

const StudyMaterialsSection = ({ materials }) => {
  // Dummy data for testing
  const dummyMaterials = [
    {
      examName: 'Mathematics Exam',
      tutorName: 'John Doe',
      files: [
        { type: 'pdf', name: 'Maths Basics.pdf', url: 'https://example.com/pdf1.pdf' },
        { type: 'video', name: 'Algebra Basics.mp4', url: 'https://example.com/video1.mp4' },
      ],
      coverPhoto:img, // Dummy cover photo URL
    },
    {
      examName: 'Science Exam',
      tutorName: 'Jane Smith',
      files: [
        { type: 'doc', name: 'Science Principles.docx', url: 'https://example.com/doc1.docx' },
        { type: 'ppt', name: 'Chemistry Basics.pptx', url: 'https://example.com/ppt1.pptx' },
      ],
      coverPhoto: img, // Dummy cover photo URL
    },
    {
      examName: 'History Exam',
      tutorName: 'Michael Brown',
      files: [
        { type: 'pdf', name: 'World History.pdf', url: 'https://example.com/history.pdf' },
        { type: 'doc', name: 'Historical Documents.docx', url: 'https://example.com/historical-docs.docx' },
      ],
      coverPhoto: img,
    },
    {
      examName: 'Art Exam',
      tutorName: 'Sarah Green',
      files: [
        { type: 'ppt', name: 'Art Techniques.pptx', url: 'https://example.com/art-techniques.pptx' },
        { type: 'video', name: 'Famous Paintings.mp4', url: 'https://example.com/famous-paintings.mp4' },
      ],
      coverPhoto: img, // Dummy cover photo URL
    },
  ];

  // Use dummy data if no materials prop provided
  if (!materials || materials.length === 0) {
    materials = dummyMaterials;
  }

  const renderFileIcon = (fileType) => {
    switch (fileType.toLowerCase()) {
      case 'pdf':
        return <FileOutlined style={{ color: '#ff0000' }} />;
      case 'doc':
      case 'docx':
        return <FileOutlined style={{ color: '#007bff' }} />;
      case 'ppt':
      case 'pptx':
        return <FileOutlined style={{ color: '#ff7f50' }} />;
      case 'txt':
        return <FileOutlined style={{ color: '#32CD32' }} />;
      case 'mp4':
      case 'avi':
        return <FileOutlined style={{ color: '#ff1493' }} />;
      default:
        return <FileOutlined />;
    }
  };

  return (
    <div className="study-materials-section">
      <h2>Study Materials</h2>
      <Row gutter={16}>
        {materials.map((material, index) => (
          <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px' }}>
            <Card
              title={material.examName}
              extra={<Text type="secondary">Uploaded by: {material.tutorName}</Text>}
              style={{ background: '#fff8dc' }} // Wheat background color
              cover={<img alt="cover" src={material.coverPhoto} style={{ height: '200px', objectFit: 'cover' }} />} // Dummy cover photo
            >
              {material.files.map((file, fIndex) => (
                <div key={fIndex} style={{ marginBottom: '10px' }}>
                  <Space>
                    {renderFileIcon(file.type)}
                    <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                    <Text type="secondary">{file.type.toUpperCase()}</Text>
                  </Space>
                </div>
              ))}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

StudyMaterialsSection.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      examName: PropTypes.string.isRequired,
      tutorName: PropTypes.string.isRequired,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ).isRequired,
      coverPhoto: PropTypes.string.isRequired,
    })
  ),
};

export default StudyMaterialsSection;
