import React, { useEffect } from 'react';

const KeyDisabler = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // List of key codes to disable
      const disabledKeys = [
        'Alt', 'Delete', 'Shift', 'Tab', 'Control', 'Backspace', 'Escape'
      ];
      
      // Check if the pressed key is in the disabledKeys array
      if (disabledKeys.includes(event.key)) {
        event.preventDefault();
        console.log('Disabled key:', event.key);
        return false
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); 

  return (
    <div>
      <p>All keyboard keys are disabled except for letters and numbers.</p>
      <input type="text" placeholder="Try typing something..." />
    </div>
  );
};

export default KeyDisabler;
