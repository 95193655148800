// ForgotPassword.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../assets/styles/forgotPassword.css";
import { backendURL } from '../../backendURL';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [step, setStep] = useState(1); 

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${backendURL}/send-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });
      console.log("Response",response)
      if (!response.ok) {
        throw new Error('Failed to send email');
      }
      
      const data = await response.json();
      console.log('Email sent:', data);
      
      // Show success message and navigate to OTP verification
      setSuccess('Email sent. Please check your inbox for OTP.');
      setStep(2);
    } catch (error) {
      console.error('Error sending email:', error);
      setError('Failed to send email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTPSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${backendURL}/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, otp })
      });
      
      if (!response.ok) {
        throw new Error('Failed to verify OTP');
      }
      
      const data = await response.json();
      console.log('OTP verified:', data);
      
      // Show success message and proceed to password reset
      setSuccess('OTP verified. You can now reset your password.');
      setStep(3); // Move to the next step
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Failed to verify OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${backendURL}/student/updatePass`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, newPassword })
      });
      
      if (!response.ok) {
        throw new Error('Failed to reset password');
      }
      
      const data = await response.json();
      console.log('Password reset:', data);
      
      // Show success message and navigate to login page
      setSuccess('Password reset successful. You can now login with your new password.');
      navigate('/login'); // Adjust route as needed
    } catch (error) {
      console.error('Error resetting password:', error);
      setError('Failed to reset password. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div class="forgot-password-container">
    <h4 class="forgot-password-title">Forgot Password</h4>
    <form class="forgot-password-form" noValidate>
      {step === 1 && (
        <>
          <input
            class="forgot-password-input"
            type="email"
            id="email"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            class="forgot-password-button"
            type="submit"
            disabled={loading}
            onClick={handleEmailSubmit}
          >
            {loading ? <div class="spinner"></div> : 'Send OTP'}
          </button>
        </>
      )}
      {step === 2 && (
        <>
          <input
            class="forgot-password-input"
            type="text"
            id="otp"
            name="otp"
            placeholder="OTP"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          />
          <button
            class="forgot-password-button"
            type="submit"
            disabled={loading}
            onClick={handleVerifyOTPSubmit}
          >
            {loading ? <div class="spinner"></div> : 'Verify OTP'}
          </button>
        </>
      )}
      {step === 3 && (
        <>
          <input
            class="forgot-password-input"
            type="password"
            id="newPassword"
            name="newPassword"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button
            class="forgot-password-button"
            type="submit"
            disabled={loading}
            onClick={handleResetPasswordSubmit}
          >
            {loading ? <div class="spinner"></div> : 'Reset Password'}
          </button>
        </>
      )}
      {error && <div class="forgot-password-alert error">{error}</div>}
      {success && <div class="forgot-password-alert success">{success}</div>}
    </form>
  </div>
  );
};


export default ForgotPassword;
