import React, { useState, useEffect } from "react";
import { message, Card, Col, Row, Typography, Button, Spin } from "antd";
import scholarshipImg from "../assets/images/scholarship2.png";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { backendURL } from "../backendURL";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

function Home() {
  const [examList, setExamList] = useState([]);
  const [registeredExamList, setRegisteredExamList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [barChartData, setBarChartData] = useState([]);
  const history = useNavigate();

  const fetchExamData = async () => {
    try {
      const classId = localStorage.getItem("userClass");
      const id = localStorage.getItem("userId");
      // const response = await fetch(`${backendURL}/exam/get/${classId}`);
      const response = await fetch(`${backendURL}/exam/getExams/${classId}/${id}`);
      const data = await response.json();
      setExamList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching exam data:", error);
      setLoading(false);
    }
  };

  const fetchExamRegistrationDetails = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID not found in local storage");
      }

      const response = await fetch(`${backendURL}/getExamId/${userId}`);
      if (!response.ok) {
        setRegisteredExamList([])
        throw new Error("Failed to fetch exams");
      }

      const data = await response.json();
      // console.log("registered exam data",data.examDetails);
      const examRegistrations = data.examDetails
        .filter((item) => !item.isExamCompleted)
        .map((item) => ({
          examName: item.examName,
          registrationId: item.registrationId,
          isExamCompleted: false,
          examId: item.examId,
        }));

      // console.log(examRegistrations);

      setRegisteredExamList(examRegistrations);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false); // Update loading state in case of error
    }
  };

  const fetchBarChartData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID not found in local storage");
      }

      const response = await fetch(
        `${backendURL}/result/getResultByStudentid/${userId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch bar chart data");
      }

      const data = await response.json();
      // console.log(data);
      if (data.status && data.data && data.data.length > 0) {
        const barChartData = data.data.map((result, index) => ({
          name: result.examId.examName,
          percentage: result.percentage,
        }));
        setBarChartData(barChartData);
      } else {
        console.error(
          "Error: Incomplete or unexpected data received from the server"
        );
      }
    } catch (error) {
      console.error("Error fetching bar chart data:", error);
    }
  };

  useEffect(() => {
    fetchExamData();
    fetchExamRegistrationDetails();
    fetchBarChartData();
  }, []);

  const handleApplyButtonClick = async (examId, values = {}) => {
    console.log(examId);
    setLoading(true);

    try {
      const studentId = localStorage.getItem("userId");
      const studentEmail = localStorage.getItem("userEmail");
      const studentName = localStorage.getItem("userName");
      const classId = localStorage.getItem("userClass");

      if (!studentId || !studentEmail || !studentName) {
        throw new Error("Something went wrong, please login again.");
      }

      const requestBody = {
        studentId: studentId,
        classId: classId,
        examId: examId,
        examName: examId.examName,
        name: studentName,
        email: studentEmail,
      };

      // console.log("Sending request with body:", JSON.stringify(requestBody));
      const response = await fetch(`${backendURL}/exam/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
    //  console.log("response w",response)
      if (response.ok) {
        const responseData = await response.json();

        // console.log("response data: ", responseData);

        const data = requestBody;
        const {
          name,
          email,
          examId: { _id, amount, examName },
        } = data;
        const { ExamRegistrationID } = responseData;

        // Update registeredExamList state with the new registration
        const newRegistration = {
          examName: examId.examName,
          registrationId: ExamRegistrationID,
          isExamCompleted: false,
          examId: examId._id,
        };
        setRegisteredExamList((prevList) => [...prevList, newRegistration]);

        if (responseData.paymentRequired) {
          history("/payment", {
            state: { amount, name, email, _id, examName, ExamRegistrationID },
          });
        } else {
          // history("/studentDashboard");
          // console.log("fetching exam registration")
          fetchExamRegistrationDetails();
          fetchExamData();
        }
      } else {
        const errorData = await response.json();
        throw new Error(
          `Exam Registration failed: ${
            errorData.message || response.statusText
          }`
        );
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(error.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleExamButtonClick = (registrationId) => {
    setLoading(true);
    history(`/final_exam/${registrationId}`);
  };

  const handleUnregisterClick=async(exam)=>{
    try{
      const userId = localStorage.getItem("userId")
      const examId = exam.examId;
      // console.log(userId,examId)
      let response = await fetch(`${backendURL}/getExamStudId`,{
        method:"DELETE",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify({
          examId:examId,
          studentId:userId
      })
      })
      response = await response.json();
      console.log(response)
      if(response.success == true){
        message.success("exam unregistered successfully")
        // console.log("exam unregistered successfully")
        await fetchExamRegistrationDetails();
        fetchExamData();
      }
      else{
        message.error("Failed to unregister exam")
      }
    }
    catch(err){
      console.log("cant unregister exam right now",err)
      message.error("Failed to unregister exam,try again later")
    }
  }

  return (
    <div className="layout-content">
      {/* Hero Image Section */}

      <Row gutter={[24, 0]} style={{ marginTop: "15px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24 mt-5">
          <Card bordered={false} className="criclebox cardbody h-full">
            <div className="project-ant">
              <div>
                <Title level={5}>Exam List</Title>
              </div>
            </div>
            <div className="ant-list-box table-responsive">
              {loading ? (
                <Spin size="large" />
              ) : (
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Exam Name</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {examList.map((exam, index) => (
                      <tr key={index}>
                        <td>
                          <div className="message-space">{index + 1}</div>
                        </td>
                        <td>
                          <div className="message-space">{exam.examName}</div>
                        </td>
                        <td>
                          <div className="message-space">{exam.amount} Rs.</div>
                        </td>
                        <td>
                          <Button
                            // type="primary"
                            className="button"
                            style={{backgroundColor:"hsl(235, 70%, 60%)"}}
                            onClick={() => handleApplyButtonClick(exam)}
                          >
                            Apply
                          </Button>
                        </td>
                        
                      </tr>
                    ))}
                    {
                      examList.length == 0 && <tr><td>No exams to register</td></tr>
                    }
                  </tbody>
                </table>
              )}
            </div>
          </Card>
        </Col>
        {/* Register exam table    */}
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-24">
          <Card bordered={false}>
            <div className="project-ant">
              <div>
                <Title level={5}>Registered Exam</Title>
              </div>
            </div>
            <div className="ant-list-box table-responsive">
              {registeredExamList ? (
                <table className="width-100">
                  <thead>
                    <tr className="ml-4">
                      <td>#</td>
                      <td>Exam Name</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {registeredExamList.map((exam, index) => (
                      <tr key={index}>
                        <td>
                          <div className="message-space">{index + 1}</div>
                        </td>
                        <td>
                          <div className="message-space">{exam.examName}</div>
                        </td>
                        <td>
                          <Button
                            className="button"
                            style={{backgroundColor:"hsl(235, 70%, 60%)"}}
                            onClick={() =>
                              handleExamButtonClick(exam.registrationId)
                            }
                          >
                            Start Exam
                          </Button>
                        </td>
                        <td>
                          <Button
                            // type="primary"
                            className="button"
                            style={{backgroundColor:"hsl(235, 70%, 60%)"}}
                            onClick={() => handleUnregisterClick(exam)}
                          >
                            Unregister
                          </Button>
                        </td>
                      </tr>
                    ))}
                    {
                      registeredExamList.length == 0 && <tr><td>No registered exams</td></tr>
                    }
                  </tbody>
                </table>
              ) : (
                <div>No registered exams</div>
              )}
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-24">
          <Card bordered={false} className="criclebox cardbody h-full">
            <div className="project-ant">
              <div>
                <Title level={5}>Bar Chart</Title>
              </div>
            </div>
            <div className="chart-container">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={barChartData}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="percentage" name="Percentage" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Home;

// NEW HOME.js

// import React, { useState, useEffect } from "react";
// import {
//   Card,
//   Col,
//   Row,
//   Typography,
//   Button,
//   Spin,
//   Table,
//   Avatar,
//   List,
//   message,
// } from "antd";
// import { ContainerOutlined, CreditCardOutlined } from "@ant-design/icons";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import { backendURL } from "../backendURL";
// import { useNavigate } from "react-router-dom";
// import "../App.css";
// import HeroImage from "../assets/images/image.webp";

// const { Title, Text } = Typography;

// const Home = () => {
//   const [examList, setExamList] = useState([]);
//   const [registeredExamList, setRegisteredExamList] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [barChartData, setBarChartData] = useState([]);
//   const history = useNavigate();

//   const buttonStyle = {
//     backgroundColor: "#936ed8", // White background
//     color: "#fff", // Black text
//     borderColor: "#9a63ff", // Optional: Adjust border color if needed
//   };

//   useEffect(() => {
//     const userId = localStorage.getItem("userId");
//     if (!userId) {
//       console.error("User ID not found in local storage");
//       return;
//     }

//     const fetchExamData = async () => {
//       try {
//         const classId = localStorage.getItem("userClass");
//         const response = await fetch(`${backendURL}/exam/get/${classId}`);
//         const data = await response.json();
//         console.log("Exam data", data);
//         setExamList(data.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching exam data:", error);
//         setLoading(false);
//       }
//     };

//       const fetchExamRegistrationDetails = async () => {
//        try {
//          const userId = localStorage.getItem("userId");
//          if (!userId) {
//            throw new Error("User ID not found in local storage");
//          }
//          const response = await fetch(`${backendURL}/getExamId/${userId}`);
//          if (!response.ok) {
//            throw new Error("Failed to fetch exams");
//          }
//          const data = await response.json();
//          console.log(data.examDetails)
//          const examRegistrations = data.examDetails.filter(item => !item.isExamCompleted).map(item => ({
//            examName: item.examName,
//            registrationId: item.registrationId,
//            isExamCompleted: false,
//            examId: item.examId
//          }));
//          console.log(examRegistrations);
//          setRegisteredExamList(examRegistrations);
//          setLoading(false);
//        } catch (error) {
//          console.error("Error:", error.message);
//          setLoading(false);
//        }
//      };

//     const fetchBarChartData = async () => {
//       try {
//         const userId = localStorage.getItem("userId");
//         if (!userId) {
//           throw new Error("User ID not found in local storage");
//         }

//         const response = await fetch(
//           `${backendURL}/result/getResultByStudentid/${userId}`
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch bar chart data");
//         }

//         const data = await response.json();
//         console.log(data);
//         if (data.status && data.data && data.data.length > 0) {
//           const barChartData = data.data.map((result) => ({
//             name: result.examId.examName,
//             percentage: result.percentage,
//           }));
//           setBarChartData(barChartData);
//         } else {
//           console.error(
//             "Error: Incomplete or unexpected data received from the server"
//           );
//         }
//       } catch (error) {
//         console.error("Error fetching bar chart data:", error);
//       }
//     };

//     fetchExamData();
//     fetchExamRegistrationDetails();
//     fetchBarChartData();
//   }, []);
//   const userName = localStorage.getItem("userName");

//   const handleApplyButtonClick = async (examId, values = {}) => {
//     setLoading(true);

//     try {
//       const studentId = localStorage.getItem("userId");
//       const studentEmail = localStorage.getItem("userEmail");
//       const studentName = localStorage.getItem("userName");
//       const classId = localStorage.getItem("userClass");

//       if (!studentId || !studentEmail || !studentName) {
//         throw new Error("Something went wrong, please login again.");
//       }

//       const requestBody = {
//         studentId: studentId,
//         classId: classId,
//         examId: examId,
//         name: studentName,
//         email: studentEmail,
//       };

//       // console.log('Sending request with body:', JSON.stringify(requestBody));
//       const response = await fetch(`${backendURL}/exam/register`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(requestBody),
//       });

//       if (response.ok) {
//         const responseData = await response.json();

//         console.log("response : ", responseData);

//         const data = requestBody;
//         console.log("data ::", data);
//         const {
//           name,
//           email,
//           examId: { _id, amount, examName },
//         } = data;
//         const { ExamRegistrationID } = responseData;

//         if (responseData.paymentRequired) {
//           history("/payment", {
//             state: { amount, name, email, _id, examName, ExamRegistrationID },
//           });
//         } else {
//           history("/studentDashboard");

//         }
//         // fetchExamRegistrationDetails();
//       } else {
//         const errorData = await response.json();
//         throw new Error(
//           `Exam Registration failed: ${
//             errorData.message || response.statusText
//           }`
//         );
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       message.error(error.message || "An unexpected error occurred.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleExamButtonClick = (registrationId) => {
//     setLoading(true);
//     history(`/final_exam/${registrationId}`);
//   };

//   return (
//     <>
//       <div className="layout1-content">
//         <div className="hero1-section">
//           <div className="hero1-text">
//             <Title
//               level={2}
//               style={{ color: "white", flex: 1, fontSize: "50px" }}
//             >
//               Welcome back, {userName}!
//             </Title>
//             <Text style={{ color: "white", flex: 1, fontSize: "20px" }}>
//               Always stay updated in your student portal
//             </Text>
//           </div>
//           <img src={HeroImage} alt="Hero" className="hero1-image " />
//         </div>
//       </div>

//       <Row gutter={[24, 24]} justify="center" align="top" style={{ minHeight: '100vh' }}>
//         <Col span={24}>
//         <Title level={4} style={{color:"4b0082"}}>Exam List</Title>
//     {loading ? (
//       <Spin size="large" />
//     ) : (
//       <Row gutter={[24, 24]}>
//         {examList.map((exam, index) => (
//           <Col xs={24} sm={12} md={8} lg={6} key={index}>
//             <Card className="exam-card">
//               <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                 <Title
//                   level={5}
//                   style={{ fontSize: "20px", color: "#8a2be2" }}
//                 >
//                   Exam Name: {exam.examName}
//                 </Title>
//                 {exam.isPaid ? (
//                   <CreditCardOutlined
//                     style={{ fontSize: "24px", color: "white", background: "linear-gradient(to right, #8a2be2, #4b0082)" }}
//                   />
//                 ) : (
//                   <ContainerOutlined
//                     style={{ fontSize: "24px", color: "white", background: "linear-gradient(to right, #8a2be2, #4b0082)" }}
//                   />
//                 )}
//               </div>
//               <Title
//                 level={5}
//                 style={{ fontSize: "20px", color: "#8a2be2" }}
//               >
//                 Amount: {exam.amount}
//               </Title>
//               <Button
//                 type="primary"
//                 style={buttonStyle}
//                 onClick={() => handleApplyButtonClick(exam)}
//               >
//                 Apply
//               </Button>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     )}
//     </Col>

//          {/* Register exam table    */}
//          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-24">
//            <Card bordered={false} className="criclebox cardbody h-full">
//              <div className="project-ant">
//                <div>
//                  <Title level={5}>Registered Exam</Title>
//                </div>
//              </div>
//              <div className="ant-list-box table-responsive">
//                {loading ? (
//                  <Spin size="large" />
//                ) : registeredExamList ? (
//                  <table className="width-100">
//                    <thead>
//                      <tr>
//                        <th>#</th>
//                        <th>Exam Name</th>
//                        <th>Action</th>
//                      </tr>
//                    </thead>
//                    <tbody>
//                      {registeredExamList.map((exam, index) => (
//                        <tr key={index}>
//                          <td>
//                            <div className="message-space">{index + 1}</div>
//                          </td>
//                          <td>
//                            <div className="message-space">{exam.examName}</div>
//                          </td>
//                          <td>
//                            <Button
//                              type="primary"
//                              style={buttonStyle}
//                              onClick={() => handleExamButtonClick(exam.registrationId)}
//                            >
//                              Start Exam
//                            </Button>
//                          </td>
//                        </tr>
//                      ))}
//                    </tbody>
//                  </table>
//                ) : (
//                  <div>No registered exams</div>
//                )}
//              </div>
//            </Card>
//          </Col>

//         <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-24">
//           <Card bordered={false}>
//             <Title level={5}>Bar Chart</Title>
//             <ResponsiveContainer width="100%" height={400}>
//               <BarChart
//                 data={barChartData}
//                 margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
//               >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <Tooltip />
//                 <Legend />
//                 <Bar dataKey="percentage" name="Percentage" fill="#82ca9d" />
//               </BarChart>
//             </ResponsiveContainer>
//           </Card>
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default Home;
