import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd"; // Import message from Ant Design

const PageVisibilityWarning = ({ onAutoSubmit }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [warningCount, setWarningCount] = useState(0);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      navigate("/studentDashboard");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setIsVisible(true);
      } else {
        setIsVisible(false);
        // Increment warning count when becoming hidden
        setWarningCount((prevCount) => prevCount + 1);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    // Auto-submit logic when warningCount reaches 3
    if (warningCount >= 4) {
      onAutoSubmit(); // Call the onAutoSubmit function passed as prop
      navigate("/studentDashboard"); // Navigate away after submission
    }
  }, [warningCount, onAutoSubmit, navigate]);

  useEffect(() => {
    // Display warning using Ant Design message.warning
    if (isVisible) {
      message.warning("You are Mistekenly switch the tab");
    }
  }, [isVisible]);

  return null; // Since we're using message.warning, we don't need to render anything
};

export default PageVisibilityWarning;
