import React, { useState } from "react";
import { Button, message, Modal } from "antd";
import Webcam from "react-webcam";

const MediaCheck = () => {
  const [micStatus, setMicStatus] = useState("unchecked");
  const [camStatus, setCamStatus] = useState("unchecked");
  const [videoStream, setVideoStream] = useState(null);

  const checkMicrophone = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicStatus("checked");
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setMicStatus("unchecked");
      message.error("Microphone access denied!");
    }
  };

  const checkWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setVideoStream(stream);
      setCamStatus("checked");
    } catch (error) {
      console.error("Error accessing webcam:", error);
      setCamStatus("unchecked");
      message.error("Webcam access denied!");
    }
  };

  const closeVideoStream = () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
      setVideoStream(null);
    }
  };

  return (
    <div>
      <Button type="primary" onClick={checkMicrophone}>
        Check Microphone
      </Button>
      <Button
        type="primary"
        onClick={checkWebcam}
        style={{ marginLeft: "10px" }}
      >
        Check Webcam
      </Button>

      <Modal
        title="Webcam Preview"
        open={!!videoStream}
        onCancel={closeVideoStream}
        footer={null}
      >
        {videoStream && (
          <Webcam
            audio={false}
            videoConstraints={{ facingMode: "user" }}
            width="100%"
            height="100%"
          />
        )}
      </Modal>
    </div>
  );
};

export default MediaCheck;
