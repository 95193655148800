import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, List, Typography, Space, Row, Col, Avatar } from 'antd';
import { UserOutlined, FileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ImageSharp } from '@material-ui/icons';

const { Text } = Typography;

const Tutor = ({ tutors }) => {
  // Dummy data for testing
  const dummyTutors = [
    {
      name: 'John Doe',
      profile: 'Computer Science',
      video: { type: 'mp4', name: 'Register and Ram', url: 'https://example.com/algebra-basics.mp4' },
      src:"https://www.youtube.com/embed/fpnE6UAfbtU?si=sLAN1pumZ2Un1SBb",
      contactInfo: 'john.doe@example.com',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg', // Dummy avatar URL
    },
    {
      name: 'Sarah Green',
      profile: 'Art Tutor',
      video: { type: 'mp4', name: 'How do serach engine work so fast.', url: 'https://example.com/art-techniques.mp4' },
      src:"https://www.youtube.com/embed/QUNrBEhvXWQ?si=i3gd_C18TNNA0aVq",
      contactInfo: 'sarah.green@example.com',
      avatar: 'https://randomuser.me/api/portraits/women/4.jpg', // Dummy avatar URL
    },
    {
      name: 'Sarah Green',
      profile: 'Art Tutor',
      video: { type: 'mp4', name: 'The Map to mathematics', url: 'https://example.com/art-techniques.mp4' },
      src:"https://www.youtube.com/embed/OmJ-4B-mS-Y?si=n4cC1nuMVp9S40_9",
      contactInfo: 'sarah.green@example.com',
      avatar: 'https://randomuser.me/api/portraits/women/4.jpg', // Dummy avatar URL
    },
  ];

  // Use dummy data if no tutors prop provided
  if (!tutors || tutors.length === 0) {
    tutors = dummyTutors;
  }

  const renderFileIcon = (fileType) => {
    switch (fileType.toLowerCase()) {
      case 'mp4':
      case 'avi':
        return <FileOutlined style={{ color: '#ff1493' }} />;
      default:
        return <FileOutlined />;
    }
  };


  return (
    <div className="tutor-section">
      <h2>Courses</h2>
      <Row gutter={16}>
        {tutors.map((tutor, index) => (
          <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px' }}>
            <Card
              title={tutor.name}
              extra={<Text type="secondary">{tutor.profile}</Text>}
              style={{ background: 'hsl(235, 70%, 60%)',color:"white" }} // Wheat background color
            >
            <iframe width="350" height="250" src={tutor.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <div style={{ marginBottom: '10px' }}>
                <Space>
                  <Avatar src={tutor.avatar} icon={<UserOutlined />} />
                  <Text>{tutor.contactInfo}</Text>
                </Space>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <Space>
                  {renderFileIcon(tutor.video.type)}
                  <Link style={{color:"black"}} href={tutor.src} target="_blank" rel="noopener noreferrer">{tutor.video.name}</Link>
                </Space>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

Tutor.propTypes = {
  tutors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile: PropTypes.string.isRequired,
      video: PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
      contactInfo: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
    })
  ),
};

export default Tutor;
