import React, { useState, useEffect } from "react";
import { Row, Col, Button, Dropdown, Menu, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";


const notificationBellIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={1} // Unique key for React rendering
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1C13.3137 1 16 3.68629 16 7C16 9.35469 17 12 19 13V14C19 14.5523 18.5523 15 18 15H2C1.44772 15 1 14.5523 1 14V13C3 12 4 9.35469 4 7C4 3.68629 6.68629 1 10 1ZM15 16C14.4477 16 14 16.4477 14 17C14 17.5523 14.4477 18 15 18C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16ZM5 14V13C5 10.347 7.347 8 10 8C12.653 8 15 10.347 15 13V14H5Z"
      fill="#111827" // Ensure to set fill color
    />
  </svg>
);


const profileIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>
);

const togglerIcon = (
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>
);

function HeaderRtl({ onPress, handleNotificationSidebar }) {
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showLogoutModal = () => {
    setLogoutModalVisible(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userName");
    localStorage.removeItem("userClass");
    navigate("/login");
  };

  const handleCancel = () => {
    setLogoutModalVisible(false);
  };



  const userName = localStorage.getItem("userName");

  return (
    <Row gutter={[24, 0]} className="rowItems" >
      <Col span={24} md={6} className="welcome-message">
        <h1>
          Welcome back, {userName}
          {/* <span className="student-name">{userName}!</span> */}
        </h1>
      </Col>
      <Col span={24} md={18} className="header-control">
        <Button type="link" className="sidebar-toggler" onClick={onPress}>
          {togglerIcon}
        </Button>
        <Button type="link" onClick={handleNotificationSidebar}>
          {notificationBellIcon}
        </Button>
       
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="0">
                <Link to="/profile">Profile</Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item style={{backgroundColor:'red',color:'white',fontWeight:"600"}} key="3" onClick={showLogoutModal}>
                Logout
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          <Button type="link">{profileIcon}</Button>
        </Dropdown>
        <Modal
          title="Confirm Logout"
          visible={logoutModalVisible}
          onOk={handleLogout}
          onCancel={handleCancel}
          okText="Logout"
          cancelText="Cancel"
        >
          <p>Are you sure you want to log out?</p>
        </Modal>
      </Col>
    </Row>
  );
}

export default HeaderRtl;
