import React, { useState,useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';
import img from '../assets/images/Razorpay.png';
import { backendURL } from '../backendURL';
import { message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

const StyledButton = styled(Button)({
  backgroundColor: '#007BFF',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
});

const RazorpayPayment = () => {
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [keyId, setKeyId] = useState(null);
  const [razorpayInstance, setRazorpayInstance] = useState(null); // State to store Razorpay instance
  const history = useNavigate();
  const location = useLocation();

  const { name, email, _id, examName, amount, ExamRegistrationID } = location.state;

  useEffect(() => {
    const handleWindowClose = () => {
      if (razorpayInstance) {
        razorpayInstance.close();
        alert('Payment window closed. Please complete the payment to proceed.'); // Show alert when window is closed
      }
    };

    window.addEventListener('beforeunload', handleWindowClose);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [razorpayInstance]);

  const createOrder = async () => {
    setLoading(true);
    try {
      const orderResponse = await fetch(`${backendURL}/createOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: amount,
          currency: 'INR',
        }),
      });

      const responseData = await orderResponse.json();

      const orderData = responseData.data;

      setOrderId(orderData.id);
      setKeyId(orderData.key_id);
      setLoading(false);

      localStorage.setItem('orderId', orderData.id);
      handlePaymentVerify(orderData);

    } catch (error) {
      console.error('Error creating order:', error);
      setLoading(false);
    }
  };

  const handlePaymentVerify = async (orderData) => {
    const studId = localStorage.getItem('userId');

    const options = {
      amount: orderData.amount * 100,
      currency: 'INR',
      name: 'TEST',
      description: 'Test Transaction',
      order_id: orderData.id,
      handler: async (response) => {
        console.log('response', response);
        try {
          const payload = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            studentId: studId,
            amount: amount,
            examID: _id,
            examRegID: ExamRegistrationID,
          };

          const res = await fetch(`${backendURL}/verify-payment`, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify(payload),
          });

          const verifyData = await res.json();

          if (verifyData.message) {
            message.success(verifyData.message);
            history('/studentDashboard');
          }
        } catch (error) {
          console.error('Error verifying payment:', error);
        }
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzp1 = new window.Razorpay(options);

    rzp1.on('payment.error', function (response) {
      console.error('Payment error:', response.error);
      alert('Payment failed: ' + response.error.description); // Show alert for payment failure
    });

    rzp1.on('payment.cancel', function (response) {
      console.log('Payment cancelled:', response);
      alert('Payment cancelled'); // Show alert for payment cancellation
    });

    rzp1.open();

    // Store the Razorpay instance to close it if necessary
    setRazorpayInstance(rzp1);
  };

  if (!name) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <Paper elevation={3} style={{ padding: '30px', textAlign: 'center', width: '100%' }}>
          <img src={img} alt="Razorpay" loading='lazy' style={{ height: '50px', marginBottom: '20px', objectFit: 'contain' }} />
          <Typography variant="body1" gutterBottom>
            Please wait after Payment Success 
          </Typography>
          <Typography variant="h4" gutterBottom>
            Razorpay Payment Integration
          </Typography>
          <Typography variant="body1" gutterBottom>
            Name: {name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Email: {email}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Exam Name: {examName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Amount: {amount} Rs
          </Typography>
          {orderId && (
            <Typography variant="body2" color="green" gutterBottom>
              Order ID generated successfully!
              <br />
              {orderId}
            </Typography>
          )}
          <div style={{ marginTop: '20px' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <StyledButton variant="contained" onClick={createOrder}>
                Pay Now
              </StyledButton>
            )}
          </div>
        </Paper>
      </Box>
    </Container>
  );
};

export default RazorpayPayment;
