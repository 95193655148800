import React from 'react';
import "../../assets/styles/style.css"; 

// Import images
import illu from "../../assets/images/illu1.jpg";
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <main><article>

<section
      className="hero"
      id="home"
      aria-label="hero"
      // style={{ backgroundImage: `url(${heroBg})` }}
    >
      <div className="container">
        <div className="hero-content">
          <p className="section-subtitle"style={{fontSize:"20px"}}>Better Learning Future With Us</p>
          <h1 className="h2 section-title" style={{fontSize:"50px",color:"hsl(247, 35%, 19%)"}}>Education Is About Academic Excellence</h1>
          
          <Link to="/signup" className="btn btn-primary w-100" style={{background:"#525de0"}}>
            <span className="span">Get Started Today</span>
            <ion-icon name="arrow-forward-outline" aria-hidden="true" />
          </Link>
        </div>
        <div className='hero-banner'>
          <img src={illu} height="500px" width="500px" />
        </div>
      </div>
    </section>
    </article>
    
    </main>
  
  );
};

export default Hero;
