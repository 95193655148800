import React, { useState, useEffect } from "react";
import {
  Grid,
  CardHeader,
  CardContent,
  Avatar,
  Card,
  Button,
  TextField,
  Typography,
  Snackbar,
} from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import MuiAlert from "@mui/material/Alert";
import { backendURL } from "../backendURL";

function Profile() {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [studentDetails, setStudentDetails] = useState(null); // Initialize as null

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    Gender: "",
    DOB: "",
    profilePicture: null,
  });

  const [successAlertOpen, setSuccessAlertOpen] = useState(false);

  const handleSuccessAlertClose = () => {
    setSuccessAlertOpen(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "profilePicture") {
      setFormData((prevData) => ({
        ...prevData,
        profilePicture: files[0], 
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
    // Populate formData with current student details for editing
    setFormData({
      firstName: studentDetails?.firstName || "",
      lastName: studentDetails?.lastName || "",
      email: studentDetails?.email || "",
      Gender: studentDetails?.Gender || "",
      DOB: studentDetails?.DOB || "",
      profilePicture: "", // Reset profile picture when entering edit mode
    });
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const studentId = localStorage.getItem("userId");
      const url = `${backendURL}/updateStudentProfile/${studentId}`;

      const formDataToSend = new FormData();
      formDataToSend.append("firstName", formData.firstName);
      formDataToSend.append("lastName", formData.lastName);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("Gender", formData.Gender);
      formDataToSend.append("DOB", formData.DOB);
      if (formData.profilePicture) {
        formDataToSend.append("profilePicture", formData.profilePicture); // Use 'profilePicture' as field name
      }

      const response = await fetch(url, {
        method: "PUT",
        body: formDataToSend,
      });

      if (response.ok) {
        fetchStudentDetails(); // Refresh student details after update
        setEditMode(false); // Exit edit mode
        setSuccessAlertOpen(true); // Open success alert
      } else {
        console.error("Failed to update student details");
      }
    } catch (error) {
      console.error("Error updating student details:", error);
    }
  };

  useEffect(() => {
    fetchStudentDetails();
  }, []);

  const fetchStudentDetails = async () => {
    setLoading(true);
    try {
      const studentId = localStorage.getItem("userId");
      const response = await fetch(
        `${backendURL}/student/getsingle/${studentId}`
      );
      const responseData = await response.json();
      console.log("Stud details", responseData.data);
      console.log("Profile Picture",responseData.data.profilePicture)
      setStudentDetails(responseData.data); // Set studentDetails with fetched data
    } catch (error) {
      console.error("Error fetching student details:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Handle loading state
  }

return (
  <>
    <div
      className="profile-nav-bg"
      // style={{ background: "linear-gradient(to right, #9a63ff, #b395ff)" }}
    ></div>

    <Card className="card-profile-head">
      <CardHeader
        title={
          <Grid container justify="space-between" alignItems="center">
            <Grid item md={6}>
              <Avatar
                alt="Profile Picture"
                src={`data:image/jpeg;base64,${studentDetails?.profilePicture}`} 
              />
              <div className="avatar-info">
                <Typography variant="h4" className="font-semibold m-0">
                  {studentDetails?.firstName && studentDetails?.lastName ? (
                    `${studentDetails.firstName} ${studentDetails.lastName}`
                  ) : (
                    "Name Not Available"
                  )}
                </Typography>
                <Typography variant="subtitle1">
                  {studentDetails?.role || "Role Not Available"}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              {!editMode ? (
                <IconButton aria-label="edit" onClick={handleEditClick}>
                  <EditIcon />
                </IconButton>
              ) : (
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginRight: 10 }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        }
      />
    </Card>

    <Grid container spacing={2} style={{ marginTop: 80 }}>
      <Grid item xs={12} md={6}>
        <Card className="card-profile-head">
          <CardContent>
            <Avatar
              alt="Profile Picture"
              src={`data:image/jpeg;base64,${studentDetails?.profilePicture}`}
              style={{
                width: '100px', // Adjust width as needed
                height: '100px', // Adjust height as needed
                borderRadius: '50%', // Makes the avatar circular
              }}
            />
            <CardContent>
              <Typography variant="body1" gutterBottom>
                Student ID: {studentDetails?.studentId || "ID Not Available"}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Student Name: {studentDetails?.firstName}{" "}
                {studentDetails?.lastName}
              </Typography>
              {/* Add more details here */}
            </CardContent>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card className="card-profile-head">
          <CardContent>
            <Typography variant="h4" className="font-semibold m-0">
              Personal Details
            </Typography>
            {!editMode ? (
              <>
                <Typography variant="body1" gutterBottom>
                  Email: {studentDetails?.email || "Email Not Available"}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Class:{" "}
                  {studentDetails?.StudClass
                    ? studentDetails.StudClass.className
                    : "No class found"}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Gender: {studentDetails?.Gender || "Gender Not Available"}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Date Of Birth: {studentDetails?.DOB || "DOB Not Available"}
                </Typography>
              </>
            ) : (
              <form onSubmit={handleSubmit}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  label="Gender"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="Gender"
                  value={formData.Gender}
                  onChange={handleChange}
                />
                <input
                  type="date"
                  fullWidth
                  margin="normal"
                  name="DOB"
                  value={formData.DOB}
                  onChange={handleChange}
                  style={{ marginTop: 20 }}
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleChange}
                  name="profilePicture"
                  style={{ marginTop: 20 }}
                />
                {formData.profilePicture && (
                  <img
                    src={URL.createObjectURL(formData.profilePicture)}
                    alt="Preview"
                    style={{
                      maxWidth: 100,
                      maxHeight: 100,
                      marginTop: 10,
                    }}
                  />
                )}
              </form>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>

    {/* Snackbar for success alert */}
    <Snackbar
      open={successAlertOpen}
      autoHideDuration={6000}
      onClose={handleSuccessAlertClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleSuccessAlertClose}
        severity="success"
      >
        Profile Updated Successfully!
      </MuiAlert>
    </Snackbar>
  </>
);
}

export default Profile;
