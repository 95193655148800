import React, { useState, useEffect, useRef } from "react";
import { Form, Radio, Button, message, Card, Typography, Divider } from "antd";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";

const { Title, Paragraph } = Typography;

const questions = [
  {
    id: 1,
    question: "What is the capital of India?",
    options: ["Mumbai", "Kolkata", "New Delhi", "Chennai"],
  },
  {
    id: 2,
    question: "Who was the first Prime Minister of India?",
    options: [
      "Jawaharlal Nehru",
      "Mahatma Gandhi",
      "Indira Gandhi",
      "Rajiv Gandhi",
    ],
  },
  {
    id: 3,
    question: "Which Indian state is known as the Land of Five Rivers?",
    options: ["Punjab", "Gujarat", "Rajasthan", "Haryana"],
  },
  {
    id: 4,
    question: "What is the square root of 144?",
    options: ["10", "12", "14", "16"],
  },
  {
    id: 5,
    question: "Complete the series: 2, 5, 10, 17, 26, ...",
    options: ["35", "36", "37", "38"],
  },
  {
    id: 6,
    question:
      "If the pattern is as follows: Square, Circle, Triangle, Square, Circle, Triangle, what shape comes next?",
    options: ["Square", "Circle", "Triangle", "Rectangle"],
  },
  {
    id: 7,
    question:
      "Which country won the most gold medals in the Tokyo 2020 Olympics?",
    options: ["USA", "China", "Japan", "Russia"],
  },
  {
    id: 8,
    question: "Which sport is known as 'The Beautiful Game'?",
    options: ["Football (Soccer)", "Basketball", "Tennis", "Golf"],
  },
  {
    id: 9,
    question: "Who is known as the 'God of Cricket'?",
    options: ["Sachin Tendulkar", "Virat Kohli", "Don Bradman", "Brian Lara"],
  },
  {
    id: 10,
    question: "Which country has won the most FIFA World Cup titles?",
    options: ["Brazil", "Germany", "Italy", "Argentina"],
  },
];

function QuestionForm({
  currentQuestion,
  onNext,
  onPrevious,
  onSaveAndNext,
  onSubmit,
  savedAnswers,
}) {
  const question = questions[currentQuestion];
  const [selectedAnswer, setSelectedAnswer] = useState(
    savedAnswers[currentQuestion]?.answer || null
  );

  const handleAnswerChange = (e) => {
    setSelectedAnswer(e.target.value);
  };

  const isLastQuestion = currentQuestion === questions.length - 1;

  return (
    <div
      style={{
        maxWidth: 800,
        margin: "20px auto",
        padding: 20,
        border: "1px solid #ccc",
        borderRadius: 10,
      }}
    >
      <Form>
        <Form.Item>
          <p>
            Q{question.id}: {question.question}
          </p>
        </Form.Item>
        <Form.Item name="answer">
          <Radio.Group onChange={handleAnswerChange} value={selectedAnswer}>
            {question.options.map((option, index) => (
              <Radio key={index} value={option.toLowerCase()}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button
            style={{ marginRight: 10 }}
            onClick={onPrevious}
            disabled={currentQuestion === 0}
          >
            Previous
          </Button>
          {isLastQuestion ? (
            <Button
              style={{ marginRight: 10 }}
              type="default"
              onClick={() => onSaveAndNext(selectedAnswer)}
            >
              Save
            </Button>
          ) : (
            <>
              <Button
                style={{ marginRight: 10 }}
                type="primary"
                onClick={onNext}
              >
                Next
              </Button>
              <Button
                style={{ marginRight: 10 }}
                type="default"
                onClick={() => onSaveAndNext(selectedAnswer)}
              >
                Save & Next
              </Button>
            </>
          )}
          {isLastQuestion && (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => onSubmit(selectedAnswer)}
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
}

function MockExam() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [savedAnswers, setSavedAnswers] = useState({});
  const [cameraAccess, setCameraAccess] = useState(false);
  const [seconds, setSeconds] = useState(1800); // 30 minutes timer
  const [showInstructions, setShowInstructions] = useState(true); // State to toggle instructions
  const [isFullScreen, setIsFullScreen] = useState(false); // State to track full-screen mode
  const webcamRef = useRef(null);
  const history = useNavigate();

  // Timer
  useEffect(() => {
    let timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    const getCameraAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setCameraAccess(true);
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.error("Error accessing camera:", error);
        setCameraAccess(false);
      }
    };

    if (seconds === 0) {
      clearInterval(timer);
      message.success("Time's up! Form submitted automatically.");
      handleSubmit();
      history("/studentDashboard");
    }

    getCameraAccess();

    return () => clearInterval(timer);
  }, [seconds]);

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    }
  };

  const handleSaveAndNext = (answer) => {
    setSavedAnswers({
      ...savedAnswers,
      [currentQuestion]: { id: questions[currentQuestion].id, answer },
    });
    handleNext();
  };

  const handleSubmit = () => {
    console.log("Submit clicked");
    console.log("Saved Answers:", savedAnswers);
    message.success("Mock Exam Saved Successfully");
    history("/studentDashboard");
  };

  const handleWebcamError = (error) => {
    console.error("Error accessing webcam:", error);
    message.error("Error accessing webcam. Please check your settings.");
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleStartExam = () => {
    setShowInstructions(false);
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  return (
    <div className="layout-content">
      {showInstructions ? (
        <Card className="instructions-card" style={{ width: "100%" }}>
          <Typography>
            <Title level={2}>Exam Instructions</Title>
            <Paragraph style={{ fontSize: "18px", lineHeight: "1.6" }}>
              Please read the following instructions carefully before starting
              the exam:
            </Paragraph>
            <ul style={{ fontSize: "18px", lineHeight: "1.6" }}>
              <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                Ensure you have a stable internet connection.
              </li>
              <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                Find a quiet environment to minimize distractions.
              </li>
              <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                Make sure your webcam and microphone are working properly.
              </li>
              <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                Ensure your device is fully charged or plugged in.
              </li>
              <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                Do not refresh the page during the exam.
              </li>
              <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                Do not switch between tabs during the exam. You will receive three
                warnings, and the exam will be automatically submitted.
              </li>
              <li>
              <Button type="primary" onClick={handleStartExam}>
                Start Exam
              </Button>
              </li>
            </ul>
            <Divider />
          </Typography>
        </Card>
      ) : (
        <>
          {cameraAccess ? (
            <div>
              <Webcam
                audio={true}
                video={true}
                ref={webcamRef}
                style={{
                  width: isFullScreen ? "100vw" : 160,
                  height: isFullScreen ? "100vh" : 120,
                  objectFit: "cover",
                }}
                screenshotFormat="image/jpeg"
                onUserMediaError={handleWebcamError}
              />
            </div>
          ) : (
            <Card className="camera-error" style={{ width: "100%" }}>
              <Typography>
                <Title level={4}>Camera Access Error</Title>
                <Paragraph>
                  Unable to access the webcam. Please grant camera permissions
                  to proceed with the exam.
                </Paragraph>
              </Typography>
            </Card>
          )}

          <QuestionForm
            currentQuestion={currentQuestion}
            onNext={handleNext}
            onPrevious={handlePrevious}
            onSaveAndNext={handleSaveAndNext}
            onSubmit={handleSubmit}
            savedAnswers={savedAnswers}
          />
        </>
      )}
    </div>
  );
}

export default MockExam;
