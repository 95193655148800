import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";

const Camera = ({ onCameraAccessChange }) => {
  const webcamRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [stream, setStream] = useState(null);
  const [cameraAccess, setCameraAccess] = useState(false);

  useEffect(() => {
    const getCameraAccess = async () => {
      try {
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        });

        if (webcamRef.current) {
          webcamRef.current.srcObject = newStream;
          setStream(newStream);
          setCameraAccess(true);
          onCameraAccessChange(true);
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
        setErrorMessage("Error accessing camera: " + error.message);
        setCameraAccess(false);
        onCameraAccessChange(false);
      }
    };

    getCameraAccess();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [onCameraAccessChange, stream]);

  const styles = {
    container: {
      position: "relative",
      width: "80%",
      height: "80%",
      maxWidth: "300px",
      float: "right",
      marginRight: "-150px",
      marginBottom: "30px",
      marginTop: "-40px",
    },
    webcam: {
      width: "50%",
      height: "50%",
    },
  };

  // Media query for small screens
  const smallScreenStyles = {
    container: {
      width: "50%", // Full width on small screens
      float: "none", // No float on small screens
      margin: "auto", // Center align on small screens
      // marginBottom: "20px",
    },
    webcam: {
      width: "100%", // Full width webcam on small screens
      height: "auto", // Maintain aspect ratio
    },
  };

  // Apply media query dynamically
  if (window.matchMedia("(max-width: 768px)").matches) {
    Object.assign(styles.container, smallScreenStyles.container);
    Object.assign(styles.webcam, smallScreenStyles.webcam);
  }

  return (
    <div className="camera-container" style={styles.container}>
      {errorMessage && <div>{errorMessage}</div>}
      <Webcam
        audio={false}
        videoConstraints={{
          facingMode: "user",
        }}
        ref={webcamRef}
        style={styles.webcam}
      />
    </div>
  );
};

export default Camera;
