import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const userEmail = localStorage.getItem("userEmail");

  const isAuthenticated = userId && token && userName && userEmail;

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
