import React from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, MailOutlined, MessageOutlined } from "@ant-design/icons";
import "../../assets/styles/style.css";
import { backendURL } from "../../backendURL";
import { useNavigate, useNavigation } from "react-router-dom";

const ContactUsForm = () => {

  const navigate = useNavigate();

  const onFinish = async (values) => {
    console.log(values)
    try {
      const response = await fetch(`${backendURL}/data/contact`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body:JSON.stringify({
          name:values.name,
          email:values.email,
          message:values.message
        })
      })
      const res = await response.json()
      console.log(res)
      if(res.success){
        message.success("Your message has been sent successfully!");
        navigate("/")
      }
    } catch (error) {
      console.error("Submission error:", error); // Debugging line
      message.error("There was an error sending your message. Please try again.");
    }
  };

  return (
    <Form
      name="contact_us_form"
      onFinish={onFinish}
      layout="vertical"
      style={{ maxWidth: "500px", margin: "auto",marginTop:"150px" }}
    >
      <Form.Item
        name="name"
        label="Your Name"
        rules={[{ required: true, message: "Please enter your name" }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Enter your name" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email Address"
        rules={[
          { required: true, message: "Please enter your email address" },
          { type: "email", message: "Please enter a valid email address" },
        ]}
      >
        <Input
          prefix={<MailOutlined />}
          placeholder="Enter your email address"
        />
      </Form.Item>

      <Form.Item
        name="message"
        label="Message"
        rules={[{ required: true, message: "Please enter your message" }]}
      >
        <Input.TextArea
          prefix={<MessageOutlined />}
          placeholder="Enter your message"
          autoSize={{ minRows: 4, maxRows: 6 }}
        />
      </Form.Item>

      <Form.Item>
        <Button className="custom-button" type="primary" htmlType="submit" style={{ width: "100%" }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContactUsForm;
