import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { backendURL } from "../backendURL";

function ExamSubmit() {
  const history = useNavigate();
  const { examId } = useParams();
  const userId = localStorage.getItem("userId");
  const [timeoutValue, setTimeoutValue] = useState(5);
  const [dataSaved, setDataSaved] = useState(false);
  const isSavingRef = useRef(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history("/studentDashboard");
    }, timeoutValue * 1000);
    return () => clearTimeout(timeout);
  }, [history, timeoutValue]);

  useEffect(() => {
    if (examId && !dataSaved && !isSavingRef.current) {
      isSavingRef.current = true;
      saveToDatabase().finally(() => {
        isSavingRef.current = false;
      });
    }
  }, [examId, dataSaved]);

  const saveToDatabase = async () => {
    try {
      const response = await fetch(`${backendURL}/exam/${examId}/answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
      setDataSaved(false);
    }
  };

  const saveAndSetDataSaved = async () => {
    await saveToDatabase();
    setDataSaved(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeoutValue((prevValue) => prevValue - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h1>Thank You!</h1>
      <p>Your form has been successfully submitted.</p>
      <p>
        Please wait, you will be redirected to the home page in {timeoutValue}{" "}
        seconds.
      </p>
    </div>
  );
}

export default ExamSubmit;
