import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Main from "./components/layout/Main";
import MockExam from "./pages/MockExam";
import FinalExam from "./pages/FinalExam";
import Result from "./pages/Result";
// import "./assets/styles/main.css";
// import "./assets/styles/responsive.css";
import ExamSubmit from "./pages/ExamSubmit";
import MediaCheck from "./pages/MediaCheck";
import Content from "./pages/Content";
import Tutor from "./pages/Tutor";
import Certificate from "./pages/Certificate";
import StudyMaterialsSection from "./pages/StudyMaterialSection";





import PrivateRoute from "./components/PrivateRoute";

// Landing page
import About from "./pages/LandingPage/About";
import AvailableScholarships from "./pages/LandingPage/AvailableScholarships";
import Services from "./pages/LandingPage/Services";
import Features from "./pages/LandingPage/Features";
import EventsSection from "./pages/LandingPage/EventSection";

import ContactUsForm from "./pages/LandingPage/ContactUsForm";
import Layout from "./pages/LandingPage/Layout";
import Hero from "./pages/LandingPage/Hero";
import RegistrationForm from "../src/pages/auth/RegistrationForm";
import Login from "../src/pages/auth/Login";
import ForgotPassword from "../src/pages/auth/ForgotPassword";
import PaymentComponent from "./pages/PaymentComponent";
import NotFound from "./pages/NotFound ";
import AboutUs from "./pages/LandingPage/AboutUs";

import "./App.css";
import Camera from "./components/Camera";
import QuestionForm from "./components/FinalExam/QuestionForm";
import KeyDisabler from "./components/FinalExam/KeyDisabler";
import "../src/assets/styles/style.css"
import CourseCategory from "./pages/LandingPage/CourseCategory";
import Scolarships from "./pages/LandingPage/Scholarships";
import Courses from "./pages/Courses";





function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Hero />
           <CourseCategory/>
           <AboutUs/>
           <Scolarships/>
         
            {/* <AvailableScholarships />
            <Features />
            <EventsSection/>    */}
          </Layout>
        }
      />
      <Route
        path="/more-scholarships"
        element={
          <Layout>
            <AvailableScholarships />
          </Layout>
        }
      />
      <Route
        path="/signup"
        element={
          <Layout>
            <RegistrationForm />
          </Layout>
        }
      />
      <Route
        path="/login"
        element={
          <Layout>
            <Login />
          </Layout>
        }
      />
      <Route
        path="/forgotpassword"
        element={
          <Layout>
            <ForgotPassword />
          </Layout>
        }
      />
      <Route path="/payment" element={<PaymentComponent />} />

      <Route
        path="/contact"
        element={
          <Layout>
            <ContactUsForm />
          </Layout>
        }
      />
      <Route
        path="/about"
        element={
          <Layout>
            <About />
          </Layout>
        }
      />
      <Route
        path="/studentDashboard"
        element={
          <Main>
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          </Main>
        }
      />
          <Route
        path="/tutor"
        element={
          <Main>
            <PrivateRoute>
              <Tutor />
            </PrivateRoute>
          </Main>
        }
      />
       <Route
        path="/study-materials/:examId"
        element={
          <Main>
            <PrivateRoute>
              <StudyMaterialsSection />
            </PrivateRoute>
          </Main>
        }
      />
           <Route
         path="/certificate/:examId"
        element={
          <Main>
            <PrivateRoute>
              <Certificate />
            </PrivateRoute>
          </Main>
        }
      />
        <Route
        path="/content"
        element={
          <Main>
            <PrivateRoute>
              <Content />
            </PrivateRoute>
          </Main>
        }
      />
        <Route
        path="/courses"
        element={
          <Main>
            <PrivateRoute>
              <Courses/>
            </PrivateRoute>
          </Main>
        }
      />

      <Route
        path="/mock_exam"
        element={
          <PrivateRoute>
            <Main>
              <MockExam />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/final_exam/:registrationId"
        element={
          <PrivateRoute>
            <Main>
              <FinalExam />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/question"
        element={
          <PrivateRoute>
            <Main>
              <QuestionForm />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Main>
              <Profile />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/result"
        element={
          <PrivateRoute>
            <Main>
              <Result />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/submit/:examId"
        element={
          <PrivateRoute>
            <Main>
              <ExamSubmit />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/chkmedia"
        element={
          <PrivateRoute>
            <Main>
              <MediaCheck />
            </Main>
          </PrivateRoute>
        }
      />

      <Route
        path="/test"
        element={
          <Main>
            <KeyDisabler />
          </Main>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
