import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';
import about from "../../assets/images/foreign scholarship_1.jpg"
import { FaGraduationCap, FaBook, FaChalkboardTeacher } from 'react-icons/fa';


const About = () => {
  // Define animations for the image and text
  const imageAnimation = useSpring({
    from: { opacity: 0, transform: 'translateX(-50px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 1000 },
  });

  const textAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
    delay: 500, // Delay the text animation
  });

  return (
    <section id="about" className="py-5 position-relative">
      <Container>
        <Row className="align-items-center">
          <Col lg={6} className="position-relative mb-4 mb-lg-0">
            <animated.img
              src={about}
              alt="About Us"
              className="img-fluid rounded shadow-lg"
              style={imageAnimation}
            />
            
          </Col>
          <Col lg={6}>
            <animated.div style={textAnimation}>
              <h2 className="font-weight-bold mb-4 animated-heading">About ScholarNet</h2>
              <p className="lead animated-paragraph">
                <FaGraduationCap className="icon" />
                ScholarNet is an online scholarship portal dedicated to helping students find and apply for scholarships
                that match their unique skills and qualifications. Our mission is to make education accessible to everyone
                by providing a comprehensive database of scholarship opportunities from various sources.
              </p>
              <p className="lead animated-paragraph">
                <FaBook className="icon" />
                We believe that financial constraints should not hinder anyone from pursuing their dreams. With ScholarNet,
                students can explore a wide range of scholarship options, including merit-based scholarships, need-based
                scholarships, and specialized scholarships for specific fields of study.
              </p>
              <p className="lead animated-paragraph">
                <FaChalkboardTeacher className="icon" />
                Our platform also offers valuable resources and guidance on scholarship applications, essay writing, and
                interview preparation to empower students throughout their scholarship journey.
              </p>
            </animated.div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
