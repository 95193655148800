import React, { useState } from "react";
import { Input, Form, Button, message, Spin, Typography, Card, Divider, Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { backendURL } from "../backendURL";
import Camera from "../components/Camera";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const FinalExam = () => {
  const { Title, Paragraph } = Typography;
  const { registrationId } = useParams();
  const history = useNavigate();
  const [examCode, setExamCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [cameraAccess, setCameraAccess] = useState(false);
  const [showExamForm, setShowExamForm] = useState(false);
 
  

  const handleChange = (e) => {
    setExamCode(e.target.value);
  };

  // console.log("cameraAccess in FinalExam:", cameraAccess);

  const handleVerify = async () => {
    setLoading(true);
    try {
      const studentId = localStorage.getItem("userId");

      if (!studentId) {
        throw new Error("User ID not found in localStorage.");
      }

      const response = await fetch(
        `${backendURL}/verify-exam-code/${registrationId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ examCode, studentId }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to verify exam code.");
      }
      const responseData = await response.json();
      console.log("Exam response",responseData.exam)
      const {duration,examName } = responseData.exam;
      const examId = responseData.exam._id
      console.log("hello",examId,duration,examName)
      history('/question',{state:{examId,duration,examName}})

      // console.log(examId);
      // console.log(duration)
    } catch (error) {
      console.error("Error verifying exam code:", error.message);
      message.error(`Error verifying exam code: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCameraAccessChange = (access) => {
    setCameraAccess(access);
  };
  const handleStartExam = () => {
    setShowExamForm(true); // Show the exam form when Start Exam button is clicked
  };

  return (
    <div className="layout-content">
     <Row justify="center" align="middle" className="mt-5">
        <Col xs={24} sm={20} md={16} lg={{ span: 6, offset: 4 }} xl={{ span: 6, offset: 15 }} style={{ textAlign: "center" }}>
          <Camera onCameraAccessChange={handleCameraAccessChange} />
        </Col>
        <Col xs={24} sm={20} md={16} lg={12} xl={10}>
          <Card className="instructions-card" style={{ width: "100%" }}>
            <Typography>
              <Title level={2}>Exam Instructions</Title>
              <Paragraph style={{ fontSize: "18px", lineHeight: "1.6" }}>
                Please read the following instructions carefully before starting the exam:
              </Paragraph>
              <ul style={{ fontSize: "18px", lineHeight: "1.6" }}>
                <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                  Ensure you have a stable internet connection.
                </li>
                <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                  Find a quiet environment to minimize distractions.
                </li>
                <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                  Make sure your webcam and microphone are working properly.
                </li>
                <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                  Ensure your device is fully charged or plugged in.
                </li>
                <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                  Do not refresh the page during the exam.
                </li>
                <li style={{ listStyle: "disc", fontSize: "18px", lineHeight: "1.6" }}>
                  Do not switch between tabs during the exam. You will receive three warnings, and the exam will be automatically submitted.
                </li>
              </ul>
              <Divider />
              {!showExamForm && (
                <Button type="primary" onClick={handleStartExam}>
                  Start Exam
                </Button>
              )}
            </Typography>
          </Card>
        </Col>
      </Row>
     
      {showExamForm && (
        <Row justify="center" align="middle" className="mt-5">
          <Col xs={24} sm={20} md={16} lg={12} xl={10}>
            <Form layout="inline">
              <Form.Item label="Exam Code">
                <Input onChange={handleChange} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleVerify}
                  disabled={!cameraAccess}
                  icon={loading ? <Spin indicator={antIcon} /> : null}
                >
                  Verify
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FinalExam;
