// import React, { useState, useEffect } from "react";
// import { Typography, Modal, Table, Button } from "antd";
// import { Link, useNavigate } from "react-router-dom"; // Import useNavigate hook
// import { backendURL } from "../backendURL";

// const { Title, Text } = Typography;

// function Result() {
//   const [modalVisible, setModalVisible] = useState(false);
//   const [selectedResult, setSelectedResult] = useState(null);
//   const [ClassName, setClassName] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [registeredExamList, setRegisteredExamList] = useState(null);

//   const StudName = localStorage.getItem("userName");
//   const StudEmail = localStorage.getItem("userEmail");
//   const ClassId = localStorage.getItem("userClass");

//   const navigate = useNavigate(); // Initialize useNavigate hook

//   useEffect(() => {
//     const fetchClassName = async () => {
//       try {
//         const response = await fetch(`${backendURL}/class/get/${ClassId}`);
//         const responseData = await response.json();
//         if (responseData.status && responseData.data.length > 0) {
//           const { className } = responseData.data[0];
//           setClassName(className);
//         } else {
//           console.error("Error: No data or invalid response received.");
//         }
//       } catch (error) {
//         console.error("Error fetching class name:", error);
//       }
//     };

//     const fetchResultData = async () => {
//       try {
//         const userId = localStorage.getItem("userId");
//         if (!userId) {
//           throw new Error("User ID not found in local storage");
//         }

//         const response = await fetch(
//           `${backendURL}/result/getResultByStudentid/${userId}`
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch exam registrations");
//         }

//         const data = await response.json();

//         if (
//           data.status &&
//           Array.isArray(data.data) &&
//           data.data.length > 0
//         ) {
//           const uniqueExamNames = new Set();
//           const formattedExams = data.data.reduce((acc, item) => {
//             const { examId } = item;
//             if (!uniqueExamNames.has(examId._id)) {
//               uniqueExamNames.add(examId._id);
//               acc.push({
//                 examName: examId.examName,
//                 registrationId: item.registrationId,
//                 isExamCompleted: item.isExamCompleted,
//                 examId: examId._id,
//                 result: item.marks,
//                 percentage: item.percentage,
//                 fullMarks: item.fullMarks,
//                 grade: calculateGrade(item.percentage),
//               });
//             }
//             return acc;
//           }, []);

//           setRegisteredExamList(formattedExams);
//         } else {
//           console.error("Error: No data or invalid response received.");
//         }
//         setLoading(false);
//       } catch (error) {
//         console.error("Error:", error.message);
//         setLoading(false);
//       }
//     };

//     fetchClassName();
//     fetchResultData();
//   }, [ClassId]);

//   const handleViewResult = async (record) => {
//     setSelectedResult(record);
//     try {
//       const response = await fetch(
//         `${backendURL}/result/exam/${record.examId}`
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch exam result");
//       }

//       const resultData = await response.json();
//       if (
//         resultData.status &&
//         resultData.message === "Results fetched successfully" &&
//         Array.isArray(resultData.data) &&
//         resultData.data.length > 0
//       ) {
//         const firstResult = resultData.data[0];
//         setSelectedResult((prevState) => ({
//           ...prevState,
//           result: firstResult.marks,
//           percentage: firstResult.percentage,
//           fullMarks: firstResult.fullMarks,
//           grade: calculateGrade(firstResult.percentage),
//         }));
//         setModalVisible(true);
//       } else {
//         console.error(
//           "Error: Incomplete or unexpected data received from the server"
//         );
//       }
//     } catch (error) {
//       console.error("Error fetching exam result:", error);
//     }
//   };

//   const calculateGrade = (percentage) => {
//     if (percentage >= 90) {
//       return "O";
//     } else if (percentage >= 80) {
//       return "A+";
//     } else if (percentage >= 75) {
//       return "A";
//     } else if (percentage >= 70) {
//       return "B+";
//     } else if (percentage >= 60) {
//       return "B";
//     } else if (percentage >= 50) {
//       return "C+";
//     } else if (percentage >= 40) {
//       return "C";
//     } else if (percentage >= 0) {
//       return "F";
//     } else {
//       return "ABSENT RE-APPEAR";
//     }
//   };

//   const columns = [
//     {
//       title: "Exam Name",
//       dataIndex: "examName",
//       key: "examName",
//     },
//     {
//       title: "Action",
//       key: "action",
//       render: (text, record) => (
//         <>
//           <Button onClick={() => handleViewResult(record)}>View Result</Button>
//           <Button
//             style={{ marginLeft: "10px" }}
//             onClick={() => navigate(`/certificate/${record.examId}`)}
//           >
//             Generate Certificate
//           </Button>
//         </>
//       ),
//     },
//   ];

//   const closeModal = () => {
//     setModalVisible(false);
//     setSelectedResult(null);
//   };

//   return (
//     <div style={{ padding: "20px" }}>
//       <div style={{ marginBottom: "20px" }}>
//         <Title level={2}>Student Information</Title>
//         <Text>Name: {StudName}</Text>
//         <br />
//         <Text>Email: {StudEmail}</Text>
//         <br />
//         <Text>Class: {ClassName}th Grade</Text>
//       </div>
//       <Title level={2}>Registered Exams</Title>
//       {loading ? (
//         <p>Loading...</p>
//       ) : registeredExamList ? (
//         <Table dataSource={registeredExamList} columns={columns} />
//       ) : (
//         <p>No registered exams found.</p>
//       )}
//       <Modal
//         key={selectedResult ? selectedResult.key : "modal"}
//         title={selectedResult?.examName}
//         visible={modalVisible && selectedResult !== null}
//         onCancel={closeModal}
//         footer={null}
//       >
//         {selectedResult && (
//           <div>
//             <div>
//               <Title level={3}>Grade: {selectedResult.grade}</Title>
//             </div>
//             <div>
//               <Text>Obtain Marks: {selectedResult.result}</Text>
//             </div>
//             <div>
//               <Text>Total Marks: {selectedResult.fullMarks}</Text>
//             </div>
//             <div>
//               <Text>Percentage (%): {selectedResult.percentage}</Text>
//             </div>
//           </div>
//         )}
//       </Modal>
//     </div>
//   );
// }

// export default Result;


import React, { useState, useEffect } from "react";
import { Typography, Modal, Table, Button } from "antd";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate hook
import { backendURL } from "../backendURL";

const { Title, Text } = Typography;

function Result() {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const [ClassName, setClassName] = useState("");
  const [loading, setLoading] = useState(true);
  const [registeredExamList, setRegisteredExamList] = useState(null);

  const StudName = localStorage.getItem("userName");
  const StudEmail = localStorage.getItem("userEmail");
  const ClassId = localStorage.getItem("userClass");
  const userId = localStorage.getItem("userId")

  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchClassName = async () => {
      try {
        const response = await fetch(`${backendURL}/class/get/${ClassId}`);
        const responseData = await response.json();
        if (responseData.status && responseData.data.length > 0) {
          const { className } = responseData.data[0];
          setClassName(className);
        } else {
          console.error("Error: No data or invalid response received.");
        }
      } catch (error) {
        console.error("Error fetching class name:", error);
      }
    };

    const fetchResultData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          throw new Error("User ID not found in local storage");
        }

        const response = await fetch(
          `${backendURL}/result/getResultByStudentid/${userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch exam registrations");
        }

        const data = await response.json();

        if (
          data.status &&
          Array.isArray(data.data) &&
          data.data.length > 0
        ) {
          const uniqueExamNames = new Set();
          const formattedExams = data.data.reduce((acc, item) => {
            const { examId } = item;
            if (!uniqueExamNames.has(examId._id)) {
              uniqueExamNames.add(examId._id);
              acc.push({
                examName: examId.examName,
                registrationId: item.registrationId,
                isExamCompleted: item.isExamCompleted,
                examId: examId._id,
                result: item.marks,
                percentage: item.percentage,
                fullMarks: item.fullMarks,
                grade: calculateGrade(item.percentage),
              });
            }
            return acc;
          }, []);

          setRegisteredExamList(formattedExams);
        } else {
          console.error("Error: No data or invalid response received.");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error:", error.message);
        setLoading(false);
      }
    };

    fetchClassName();
    fetchResultData();
  }, [ClassId]);

  const handleViewResult = async (record) => {
    console.log(record)
    setSelectedResult(record);
    try {
      const response = await fetch(
        `${backendURL}/result/exam/${record.examId}/${userId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch exam result");
      }

      const resultData = await response.json();
      if (
        resultData.status &&
        resultData.message === "Results fetched successfully" &&
        Array.isArray(resultData.data) &&
        resultData.data.length > 0
      ) {
        const firstResult = resultData.data[0];
        setSelectedResult((prevState) => ({
          ...prevState,
          result: firstResult.marks,
          percentage: firstResult.percentage,
          fullMarks: firstResult.fullMarks,
          grade: calculateGrade(firstResult.percentage),
        }));
        setModalVisible(true);
      } else {
        console.error(
          "Error: Incomplete or unexpected data received from the server"
        );
      }
    } catch (error) {
      console.error("Error fetching exam result:", error);
    }
  };

  const calculateGrade = (percentage) => {
    if (percentage >= 90) {
      return "O";
    } else if (percentage >= 80) {
      return "A+";
    } else if (percentage >= 75) {
      return "A";
    } else if (percentage >= 70) {
      return "B+";
    } else if (percentage >= 60) {
      return "B";
    } else if (percentage >= 50) {
      return "C+";
    } else if (percentage >= 40) {
      return "C";
    } else if (percentage >= 0) {
      return "F";
    } else {
      return "ABSENT RE-APPEAR";
    }
  };

  const columns = [
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Button onClick={() => handleViewResult(record)}>View Result</Button>
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => navigate(`/certificate/${record.examId}`)}
          >
            Generate Certificate
          </Button>
        </>
      ),
    },
  ];

  const closeModal = () => {
    setModalVisible(false);
    setSelectedResult(null);
  };

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ marginBottom: "20px" }}>
        <Title level={2}>Student Information</Title>
        <Text>Name: {StudName}</Text>
        <br />
        <Text>Email: {StudEmail}</Text>
        <br />
        <Text>Class: {ClassName}th Grade</Text>
      </div>
      <Title level={2}>Registered Exams</Title>
      {loading ? (
        <p>Loading...</p>
      ) : registeredExamList ? (
        <Table dataSource={registeredExamList} columns={columns} />
      ) : (
        <p>No registered exams found.</p>
      )}
      <Modal
        key={selectedResult ? selectedResult.key : "modal"}
        title={selectedResult?.examName}
        visible={modalVisible && selectedResult !== null}
        onCancel={closeModal}
        footer={null}
      >
        {selectedResult && (
          <div>
            <div>
              <Title level={3}>Grade: {selectedResult.grade}</Title>
            </div>
            <div>
              <Text>Status: {selectedResult.grade === "F" ? "Fail" :"Pass"}</Text>
            </div>            
            <div>
              <Text>Obtain Marks: {selectedResult.result}</Text>
            </div>
            <div>
              <Text>Total Marks: {selectedResult.fullMarks}</Text>
            </div>
            <div>
              <Text>Percentage (%): {selectedResult.percentage}</Text>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Result;
