import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/signup_img.png";
import { Form, Input, Radio,  Select, message,Spin } from "antd";
import { backendURL } from "../../backendURL";
import moment from "moment";
import "../../assets/styles/login.css";


const { Option } = Select;

const RegistrationForm = () => {
  const [classList, setClassList] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const fetchClasses = async () => {
    try {
      const response = await fetch(`${backendURL}/class/getAll`);
      if (!response.ok) {
        throw new Error("Failed to fetch classes");
      }
      const responseData = await response.json();
      if (responseData.status) {
        const sortedClasses = responseData.data.slice().sort((a, b) => {
          return a.className - b.className;
        });
        setClassList(sortedClasses);
      } else {
        throw new Error(responseData.message);
      }
    } catch (error) {
      console.error(`Error fetching classes: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(`${backendURL}/student/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          Contact: values.contact,
          StudClass: values.selectedClass,
          Gender: values.gender,
          DOB: values.dob,
          password: values.password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const responseData = await response.json();
      console.log(responseData);
      message.success("Signup successful!");
      form.resetFields();
      navigate("/login");
    } catch (error) {
      console.error("Error:", error.message);
      message.error("Signup failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
  
    <section className="login-section">
    <div className="login-content">
      <div className="login-img-container">
        <img
          src={img}
          alt="login form"
          className="login-img"
        />
      </div>
      <div className="login-form-container-1">
        <div className="login-card">
          <div className="login-row">
          
            <div className="login-form-col">
              <div className="login-card-body">
              <Spin spinning={loading} size="large">
                  <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    initialValues={{ remember: true }}
                  >
                    <h5 className="login-title">
                    Sign Up
                    </h5>
                        <Form.Item
                          label="First name"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                            {
                              pattern: /^[a-zA-Z]*$/,
                              message: "Name should only contain alphabets",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Last name"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your last name!",
                            },
                            {
                              pattern: /^[a-zA-Z]*$/,
                              message: "Name should only contain alphabets",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                            {
                              type: "email",
                              message: "Please enter a valid email address",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <Form.Item
                          label="Contact"
                          name="contact"
                          rules={[
                            {
                              required: true,
                              message: "Please input your contact number!",
                            },
                            {
                              pattern: /^[0-9]{10}$/,
                              message: "Contact should be a 10-digit number",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Gender"
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Please select your gender!",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="Female">Female</Radio>
                            <Radio value="Male">Male</Radio>
                            <Radio value="Other">Other</Radio>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                          label="DOB"
                          name="dob"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your date of birth!',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || moment().diff(value, 'years') >= 10) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('Plese Enter Correct Birth Date'));
                              },
                            }),
                          ]}
                        >
                          <Input type="date" />
                        </Form.Item>

                        <Form.Item
                          label="Select Class"
                          name="selectedClass"
                          rules={[
                            {
                              required: true,
                              message: "Please select your class!",
                            },
                          ]}
                        >
                          <Select>
                            {classList.length > 0 ? (
                              classList.map((cls) => (
                                <Option key={cls._id} value={cls._id}>
                                  {cls.className}
                                </Option>
                              ))
                            ) : (
                              <Option value="" disabled>
                                Loading classes...
                              </Option>
                            )}
                          </Select>
                        </Form.Item>

                        <Form.Item>
                          <button className="custom-button"
                            htmlType="submit"
                            loading={loading}
                          >
                            Sign Up
                          </button>
                        </Form.Item>
                      </Form>
                      </Spin>
                      </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  );
};

export default RegistrationForm;

