import React from 'react';
import PropTypes from 'prop-types';
import { Card, List, Typography, Space, Row, Col, Avatar } from 'antd';
import { UserOutlined, FileOutlined } from '@ant-design/icons';

const { Text } = Typography;

const Tutor = ({ tutors }) => {
  // Dummy data for testing
  const dummyTutors = [
    {
      name: 'John Doe',
      profile: 'Mathematics Tutor',
      video: { type: 'mp4', name: 'Algebra Basics.mp4', url: 'https://example.com/algebra-basics.mp4' },
      contactInfo: 'john.doe@example.com',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg', // Dummy avatar URL
    },
    {
      name: 'Jane Smith',
      profile: 'Science Tutor',
      video: { type: 'mp4', name: 'Chemistry Principles.mp4', url: 'https://example.com/chemistry-principles.mp4' },
      contactInfo: 'jane.smith@example.com',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg', // Dummy avatar URL
    },
    {
      name: 'Michael Brown',
      profile: 'History Tutor',
      video: { type: 'mp4', name: 'World History.mp4', url: 'https://example.com/world-history.mp4' },
      contactInfo: 'michael.brown@example.com',
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg', // Dummy avatar URL
    },
    {
      name: 'Sarah Green',
      profile: 'Art Tutor',
      video: { type: 'mp4', name: 'Art Techniques.mp4', url: 'https://example.com/art-techniques.mp4' },
      contactInfo: 'sarah.green@example.com',
      avatar: 'https://randomuser.me/api/portraits/women/4.jpg', // Dummy avatar URL
    },
  ];

  // Use dummy data if no tutors prop provided
  if (!tutors || tutors.length === 0) {
    tutors = dummyTutors;
  }

  const renderFileIcon = (fileType) => {
    switch (fileType.toLowerCase()) {
      case 'mp4':
      case 'avi':
        return <FileOutlined style={{ color: '#ff1493' }} />;
      default:
        return <FileOutlined />;
    }
  };

  return (
    <div className="tutor-section">
      <h2>Tutor Videos</h2>
      <Row gutter={16}>
        {tutors.map((tutor, index) => (
          <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6} style={{ marginBottom: '20px' }}>
            <Card
              title={tutor.name}
              extra={<Text type="secondary">{tutor.profile}</Text>}
              style={{ background: 'hsl(235, 70%, 60%)' }} // Wheat background color
            >
              <div style={{ marginBottom: '10px' }}>
                <Space>
                  <Avatar src={tutor.avatar} icon={<UserOutlined />} />
                  <Text>{tutor.contactInfo}</Text>
                </Space>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <Space>
                  {renderFileIcon(tutor.video.type)}
                  <a href={tutor.video.url} target="_blank" rel="noopener noreferrer">{tutor.video.name}</a>
                </Space>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

Tutor.propTypes = {
  tutors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile: PropTypes.string.isRequired,
      video: PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
      contactInfo: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
    })
  ),
};

export default Tutor;
