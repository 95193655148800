import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { message, Form, Input,  Spin } from "antd"; // Import Spin from antd
import img from "../../assets/images/login_img.png";
import "../../assets/styles/login.css";
import { backendURL } from "../../backendURL";

function Login() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); // State for loading indicator
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setLoading(true); // Set loading to true when form is submitted
    try {
      const response = await fetch(`${backendURL}/student/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const errorData = await response.json();
        // console.log(errorData);
        if (
          response.status === 400 &&
          errorData.message === "Invalid Password"
        ) {
          throw new Error("Password is incorrect");
        } else if (
          response.status === 400 &&
          errorData.message === "User not found"
        ) {
          throw new Error("Email is not registered");
        } else {
          throw new Error("An error occurred during login");
        }
      }

      const data = await response.json();
      console.log(data);
      localStorage.setItem("token", data.token); // Storing authentication token
      localStorage.setItem("userId", data.user._id);
      localStorage.setItem("userName", data.user.firstName);
      localStorage.setItem("userEmail", data.user.email);
      localStorage.setItem("userClass", data.user.studentClass);

      message.success("Login successful!");
      navigate("/studentDashboard");
    } catch (error) {
      console.error("Error logging in:", error);
      message.error(error.message || "Internal server error");
    } finally {
      setLoading(false); // Set loading to false after request completes
    }
  };

  return (
    <section className="login-section " style={{height: "90vh"}}>
    <div className="login-content">
      <div className="login-img-container">
        <img
          src={img}
          alt="login form"
          className="login-img"
        />
      </div>
      <div className="login-form-container">
        <div className="login-card">
          <div className="login-row">
          
            <div className="login-form-col">
              <div className="login-card-body">
                <Spin spinning={loading} size="large">
                  <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    initialValues={{ remember: true }}
                  >
                    <h2 className="login-title">
                      Sign In
                    </h2>
                    <Form.Item
                      name="email"
                      label="Email address"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input className="input" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password className="input"/>
                    </Form.Item>
                    <Form.Item className="login-btn-div">
                      <button className="custom-button" htmlType="submit">
                        Login
                      </button>
                    </Form.Item>
                  </Form>
                </Spin>
                <Link className="forgot-password-link" to="/forgotpassword">
                  Forgot password?
                </Link>

                <p className="register-text">
                  Don't have an account?
                  <Link className="register-link" to="/signup">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Login;
