import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { backendURL } from '../../backendURL';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';


const AvailableScholarships = () => {
  const [scholarships, setScholarships] = useState([]);
  const [showAllScholarships, setShowAllScholarships] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const fetchScholarships = async () => {
      try {
        const response = await fetch(`${backendURL}/scholarship/getAll`);
        if (!response.ok) {
          throw new Error('Failed to fetch scholarships');
        }
        const data = await response.json();

        setScholarships(data.data);
      } catch (error) {
        console.error('Failed to fetch scholarships:', error);
      }
    };

    fetchScholarships();
  }, []);

  const toggleShowAllScholarships = () => {
    if (isHomePage) {
      navigate('/more-scholarships');
    } else {
      navigate('/');
    }
    setShowAllScholarships(!showAllScholarships);
  };

  const lineStyle = {
    width: '50px',      
    height: '4px',     
    backgroundColor: 'maroon',
    margin: '10px auto', 
    display: 'block'    
  };

  return (
    <section className="scholarships-section">
      <div className="scholarships-overlay"></div>
      <div className="container position-relative">
        <div className="text-center mb-5">
          <h3 className="text-dark font-semibold">Popular Scholarships</h3>
          <div style={lineStyle}></div>
          <h2 className="text-3xl font-bold">Explore Available Scholarships</h2>
        </div>
        <Row xs={1} md={2} lg={3} className="g-4">
          {scholarships.slice(0, showAllScholarships || !isHomePage ? scholarships.length : 3).map((scholarship) => (
            <Col key={scholarship._id}>
              <Card className="h-100 shadow scholarship-card position-relative">
                <div className="position-relative card-container">
                  <div className="card-image-container">
                    <Card.Img
                      variant="top"
                      src={scholarship.photo}
                      alt="Scholarship Image"
                      className="card-image"
                    />
                  </div>
                  <div className="position-absolute end-0 mb-4 me-4" style={{ bottom: '-55px' }}>
                    <div className="rounded-circle overflow-hidden border border-white d-flex justify-content-center align-items-center"
                         style={{ width: '60px', height: '60px' , background:'maroon'}}>
                      <span className="text-light fs-6">Rs.{scholarship.amount}</span>
                    </div>
                  </div>
                </div>
                <div className="card-overlay"></div>
                <Card.Body className="card-body">
                  <Card.Title className="text-xl fw-bold mb-2 card-title">{scholarship.ScholarshipName}</Card.Title>
                  <div className="text-center mt-3 apply-now-container">
                    <Link 
                      to="/signup"
                      className="apply-now-btn"
                    >
                      Apply Now
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {!showAllScholarships && scholarships.length > 3 && isHomePage && (
          <div className="d-flex justify-content-center mt-4">
            <button className="custom-button d-flex align-items-center" onClick={toggleShowAllScholarships}>
              View All Scholarships
              <BsArrowRightCircle style={{ marginLeft: '8px', fontSize: '16px' }} />
            </button>
          </div>
        )}
        {!isHomePage && (
          <div className="mt-4">
            <button className="btn btn-primary d-flex align-items-center" onClick={toggleShowAllScholarships}>
              <BsArrowLeftCircle style={{ marginRight: '8px', fontSize: '16px' }} />
              Back to Home
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default AvailableScholarships;
