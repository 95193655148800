import React, { useState, useEffect } from 'react';
import { Card, Button, Col, Row, Empty } from 'antd';
import { Link } from 'react-router-dom';
import { backendURL } from '../backendURL';
import scholarshipImg from '../assets/images/scholarship1.png';

const { Meta } = Card;

const Content = () => {
  const [enrolledExams, setEnrolledExams] = useState([]);

  useEffect(() => {
    // Function to fetch enrolled exams
    const fetchEnrolledExams = async () => {
      // Retrieve studentId from localStorage
      const studentId = localStorage.getItem('userId');

      if (!studentId) {
        console.error('Student ID not found in localStorage.');
        return;
      }

      try {
        // Fetch enrolled exams data from backend
        const response = await fetch(`${backendURL}/getExamId/${studentId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch enrolled exams.');
        }
        const data = await response.json();
        setEnrolledExams(data.examDetails);
      } catch (error) {
        console.error('Error fetching enrolled exams:', error);
      }
    };

    fetchEnrolledExams();
  }, []);

  return (
    <div>
      {/* Hero Image Section */}
      {/* <div
        style={{
          backgroundImage: `url(${scholarshipImg})`,
          height: '300px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'black',
          }}
        >
          <h1>Welcome to Your Enrolled Exams</h1>
          <p>Explore your upcoming exams and courses below.</p>
        </div>
      </div> */}

      {/* Enrolled Exams Cards Section */}
      <div className="container" style={{ marginTop: '20px' }}>
        <Row gutter={20}>
          {enrolledExams.length > 0 ? (
            enrolledExams.map((exam) => (
              <Col key={exam.registrationId} xs={24} sm={12} md={8} lg={8} xl={8} style={{ marginBottom: '20px' }}>
                <Card
                  hoverable
                  actions={[
                    <Link to={`/study-materials/${exam.registrationId}`}>
                      <Button type="primary" key="view">
                        View Contents
                      </Button>
                    </Link>,
                  ]}
                >
                  <Meta title={exam.examName} />
                </Card>
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Empty description="No enrolled exams found." />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}

export default Content;
