import React from 'react';
import courseBg from '../../assets/images/course-bg.jpg';
import nasa from '../../assets/images/nasa-exam.jpg';
import { Link } from 'react-router-dom';


const Scolarships = () => {
  return (
    <section
      className="section course"
      id="courses"
      aria-label="course"
      style={{ backgroundImage: `url(${courseBg})` }}
    >
      <div className="container">
        <p className="section-subtitle">Upcoming Events</p>
        <h2 className="h2 section-title"style={{ color:"hsl(247, 35%, 19%)"}}>Join Our NASA EXAM</h2>
        <ul className="grid-list">
          <li>
            <div className="course-card">
              <figure className="card-banner">
                <img
                  src={nasa}
                  width={370}
                  height={270}
                  loading="lazy"
                  alt="Competitive Strategy law for all students"
                  className="img-cover"
                />
              </figure>
              <div className="card-actions">
                <button
                  className="whishlist-btn"
                  aria-label="Add to whishlist"
                  data-whish-btn=""
                >
                  <ion-icon name="heart" />
                </button>
              </div>
              <div className="card-content">
                <ul className="card-meta-list">
                  {/* <li className="card-meta-item">
                    <ion-icon name="reader-outline" aria-hidden="true" />
                    <span className="card-meta-text">35 Lessons</span>
                  </li> */}
                  {/* <li className="card-meta-item">
                    <ion-icon name="time-outline" aria-hidden="true" />
                    <time dateTime="PT18H15M44S" className="card-meta-text">
                      18h 15m 44s
                    </time>
                  </li> */}
                </ul>
                <h3 className="h3">
                  <a href="#" className="card-title">
                    NASA EXAM
                  </a>
                </h3>
                <p className="card-description">
                  Prepare for the NASA Exam and explore the wonders of space exploration and research.
                </p>
                {/* <div className="rating-wrapper">
                  <div className="rating">
                    <ion-icon name="star" />
                    <ion-icon name="star" />
                    <ion-icon name="star" />
                    <ion-icon name="star" />
                    <ion-icon name="star" />
                  </div>
                  <span className="rating-text">(18 Review)</span>
                </div> */}
                <div className="card-footer">
                <Link to="/signup" className="btn btn-secondary">
                    <span className="span">Learn More</span>
                    <ion-icon name="arrow-forward-outline" aria-hidden="true" />
                  </Link>
                  {/* <div className="card-price">
                    <span className="span">$29.00</span>
                    <del className="del">$39.00</del>
                  </div> */}
                  {/* <div className="card-meta-item">
                    <ion-icon name="people-outline" aria-hidden="true" />
                    <span className="card-meta-text">47 Students</span>
                  </div> */}
                </div>
              </div>
            </div>
          </li>
          {/* Repeat the above structure for other courses */}
          <li>
            {/* Course card structure */}
          </li>
        </ul>
       
      </div>
    </section>
  );
};

export default Scolarships;
