import React from 'react';
import aboutBanner from '../../assets/images/about-banner.jpg';
import aboutAbs1 from '../../assets/images/about-abs-1.jpg';
import aboutAbs2 from '../../assets/images/about-abs-2.jpg';
import aboutIcon1 from '../../assets/images/about-icon-1.png';
import aboutIcon2 from '../../assets/images/about-icon-2.png';
import aboutIcon3 from '../../assets/images/about-icon-3.png';

const AboutUs = () => {
  return (
    <div>
      <section className="section about" id="about" aria-label="about">
        <div className="container">
          <figure className="about-banner">
            <img
              src={aboutBanner}
              width={450}
              height={590}
              loading="lazy"
              alt="about banner"
              className="w-100 about-img"
            />
            <img
              src={aboutAbs1}
              width={188}
              height={242}
              loading="lazy"
              aria-hidden="true"
              className="abs-img abs-img-1"
            />
            <img
              src={aboutAbs2}
              width={150}
              height={200}
              loading="lazy"
              aria-hidden="true"
              className="abs-img abs-img-2"
            />
          </figure>
          <div className="about-content">
            <p className="section-subtitle">Who We Are</p>
            <h2 className="h2 section-title" style={{ color:"hsl(247, 35%, 19%)"}}>We Offer The Best Career</h2>
            <ul className="about-list">
              <li className="about-item">
                <div className="item-icon item-icon-1">
                  <img
                    src={aboutIcon1}
                    width={30}
                    height={30}
                    loading="lazy"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <h3 className="h3 item-title">Industry Expert Instructors</h3>
                  <p className="item-text">
                  Learn from industry experts who bring real-world experience to the classroom.
                  </p>
                </div>
              </li>
              <li className="about-item">
                <div className="item-icon item-icon-2">
                  <img
                    src={aboutIcon2}
                    width={30}
                    height={30}
                    loading="lazy"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <h3 className="h3 item-title">Up-to-Date Course Content</h3>
                  <p className="item-text">
                  Stay current with our courses that are regularly updated to reflect industry trends and best practices.
                  </p>
                </div>
              </li>
              <li className="about-item">
                <div className="item-icon item-icon-3">
                  <img
                    src={aboutIcon3}
                    width={30}
                    height={30}
                    loading="lazy"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <h3 className="h3 item-title">Biggest Student Community</h3>
                  <p className="item-text">
                  Join our vibrant student community and engage with peers from around the globe.
                  </p>
                </div>
              </li>
            </ul>
           
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
