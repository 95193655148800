import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logoImage from '../../assets/images/ScholarNet_logo.png';
import "../../assets/styles/style.css"

const Header = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`header ${scrolling ? 'header-scroll' : ''}`} data-header="" style={{ padding: "0px", background: "white" }}>
        <div className="container">
          {/* Replace text logo with image */}
          <h1 style={{ margin: "20px", textAlign: "center" }}>
  <Link to="/" className="logo">
    <img
      src={logoImage}
      alt="ScholarNet Logo"
      className="logo-img"
      style={{ maxHeight: "80px", width: "auto", display: "block", marginBottom: "20px" }}
    />
  </Link>
</h1>

          <nav className={`navbar ${scrolling ? 'navbar-scroll' : ''}`} data-navbar="">
            <div className="navbar-top">
              <button
                className="nav-close-btn"
                aria-label="Close menu"
                data-nav-toggler=""
              >
                <ion-icon name="close-outline" />
              </button>
            </div>
            <ul className="navbar-list">
              <li className="navbar-item">
                <Link to="/" className="navbar-link" data-nav-toggler="">
                  Home
                </Link>
              </li>
              <li className="navbar-item">
                <Link to="/about" className="navbar-link" data-nav-toggler="">
                  About
                </Link>
              </li>
              {/* <li className="navbar-item">
                <Link to="#event" className="navbar-link" data-nav-toggler="">
                  Event
                </Link>
              </li> */}
              {/* <li className="navbar-item">
                <Link to="#" className="navbar-link" data-nav-toggler="">
                  Blog
                </Link>
              </li> */}
              <li className="navbar-item">
                <Link to="/contact" className="navbar-link" data-nav-toggler="">
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
          <div className="header-actions">
            {/* Separate login/register buttons */}
            <Link to="/login" className="header-action-btn login-btn">
              <ion-icon name="person-outline" aria-hidden="true" />
              <span className="span">Login</span>
            </Link>
            <Link to="/signup" className="header-action-btn login-btn">
              <span className="span">Sign Up</span>
            </Link>
            <button
              className="header-action-btn nav-open-btn"
              aria-label="Open menu"
              data-nav-toggler=""
            >
              <ion-icon name="menu-outline" />
            </button>
          </div>
          <div className="overlay" data-nav-toggler="" data-overlay="" />
        </div>
      </header>
    </>
  );
};

export default Header;
